import api from './api';

export const fetchSubscriptions = async () => {
  const response = await api.get(`/subscription`);
  return response.data;
};

/**
 * Fetches subscription lists based on different query parameters.
 * @param {Object} queryKey - An object containing the query parameters for fetching subscription lists.
 * @param {number} queryKey.page - The page number.
 * @param {number} queryKey.limit - The number of items per page.
 * @param {string} [queryKey.search] - The search query.
 * @param {string} [queryKey.duration] - The duration filter.
 * @param {string} [queryKey.plan] - The plan filter.
 * @returns {Promise} - A promise that resolves to the data fetched from the API based on the provided query parameters.
 */
export const fetchUsersSubsciptionLists = async ({ queryKey }) => {
  const [, { page, limit, search, duration, plan }] = queryKey;
  let url = `/subscription/users/list?page=${page}&limit=${limit}`;

  if (search) {
    url += `&search=${search}`;
  }
  if (duration) {
    url += `&duration=${duration}`;
  }
  if (plan) {
    url += `&plan=${plan}`;
  }

  const response = await api.get(url);
  return response.data;
};

export const fetchSubscription = async ({ queryKey }) => {
  const [, id] = queryKey;
  if (id) {
    const response = await api.get(`/subscription/${id}`);
    return response.data;
  } else {
    throw new Error('No subscription ID provided');
  }
};

export const fetchSubscriptionMetrics = async () => {
  const response = await api.get(`/subscription/metric/plans`);
  return response.data;
};

export const createSubscription = async (data) => {
  const response = await api.post(`/subscription`, data);
  return response.data;
};

export const editSubscription = async (id, data) => {
  if (id) {
    const response = await api.patch(`/subscription/${id}`, data);
    return response.data;
  } else {
    throw new Error('No subscription ID provided');
  }
};

export const deleteSubscription = async (id) => {
  const response = await api.delete(`/subscription/${id}`);
  return response.data;
};

export const exportSubscription = async (data) => {
  const response = await api.post(`/export/subscription`, data);
  return response.data;
}