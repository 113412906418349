import api from "./api"

export const fetchLenders = async () => {
  const response = await api.get(`/lender`);
  return response.data;
}

export const fetchLender = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/lender/${id}`);
  return response.data;
}

export const makeLender = async (data) => {
  const response = await api.patch(`/lender/add`, data);
  return response.data;
}

export const removeLender = async (data) => {
  const response = await api.patch(`/lender/remove`, data);
  return response.data;
}

export const updateDiscount = async (data) => {
  const response = await api.patch(`/lender/set-discount-fee`, data);
  return response.data;
}

export const fetchLenderActivity = async ({ queryKey }) => {
  const [, { id, page, limit }] = queryKey;
  const response = await api.get(`/lender/${id}/activity?page=${page}&limit=${limit}`);
  return response.data;
}
export const fetchLenderInflows = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/lender/${id}/inflows`);
  return response.data;
}
export const fetchLoanRequests = async ({ queryKey }) => {
  const [, { page, limit, search }] = queryKey;
  let url = `/lender/requests/all?page=${page}&limit=${limit}`;

  if (search) {
    url += `&search=${search}`;
  }
  
  const response = await api.get(url);
  return response.data;
}

export const fetchLoanInterests = async ({ queryKey }) => {
  const [, { page, limit, search }] = queryKey;
  let url = `/lender/interests/all?page=${page}&limit=${limit}`;

  if (search) {
    url += `&search=${search}`;
  }

  // const response = await api.get(`/lender/interests/all`);
  const response = await api.get(url);
  return response.data;
}