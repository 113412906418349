import PageHeader from "@/components/PageHeader";
import withAuthentication from "@/hooks/withAuthentication";
import styled from "styled-components";
import WalletsTable from "./components/WalletsTable";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { WalletsHistory } from "./components/history/WalletsHistory";
import { usePermissions } from "@/hooks/usePermissions";
import { EmptyState } from "@/components/EmptyState";

const tabs = [
  {
    name: "Wallets Table",
    path: "wallets",
    value: "wallets",
  },
  {
    name: "Wallets Transaction History",
    path: "history&view=all-history",
    value: "history",
  }
];

function Wallets() {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const navigate = useNavigate();
  const { hasPermission, role, superAdmin } = usePermissions();

  useEffect(() => {
    // Check if 'tab' parameter is not present in the URL
    if (!searchParams.get('tab')) {
      // Set the default URL parameters for the "Subscription Plans" tab
      setSearchParams('tab=wallets');
      // Optionally, you can also navigate to the new URL
      navigate('?tab=wallets');
    }
  }, [searchParams, navigate, setSearchParams]);

  const canReadWallets = hasPermission('wallet', 'read');
  const isASuperAdmin = superAdmin(true);
  const isRoleAdmin = role('Admin');

  return (
    <>
      <WalletsView>
        <PageHeader title="Wallets" subtitle="View and manage wallets" />
        {canReadWallets || isASuperAdmin || isRoleAdmin ? (
          <div>
            <TabView>
              {tabs.map((item) => (
                <button
                  key={item.name}
                  className={`tab ${tab === item.value ? 'active' : ''}`}
                  onClick={() => setSearchParams(`tab=${item.path}`)}>
                  {item.name}
                </button>
              ))}
            </TabView>
            <TabContent>{tab === 'wallets' && <WalletsTable />}</TabContent>
            <TabContent>{tab === 'history' && <WalletsHistory />}</TabContent>
          </div>
        ) : (
          <EmptyState />
        )}
      </WalletsView>
    </>
  );
}

export default withAuthentication(Wallets);

const WalletsView = styled.div`
  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 80px;
  overflow-x: hidden;
  margin-left: 18%;
`;

const TabView = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  position: fixed;
  z-index: 100;
  margin-left: -30px;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors?.white};
  border-bottom: 1px solid ${(props) => props.theme?.colors?.line};

  button {
    padding: 16px 32px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    border-bottom: 2px solid transparent;

    &:hover {
    color: ${(props) => props.theme?.colors?.secondary};
    }
  }
  
  .active {
    color: ${(props) => props.theme?.colors?.secondary};
    border-bottom: 2px solid ${(props) => props.theme?.colors?.secondary};
  }
`;

const TabContent = styled.div`
  width: 100%;
`;