import { useNavigate, useRouteError } from "react-router-dom";
import styled from "styled-components";

export default function ErrorPage() {
  const error = useRouteError();
  const navigate = useNavigate();

  console.log(error);

  return (
    <ErrorView>
      {/* <ErrorIllustration /> */}
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        {error.status === 404 && error?.internal ? "Page not found" : error.statusText || error.message}
      </p>
      <button type="button" onClick={() => navigate(-1)}>Go back home</button>
    </ErrorView>
  );
}

const ErrorView = styled.section`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1 {
    font-size: 2rem;
    margin-bottom: 8px;
  }

  p {
    font-size: 1rem;
    margin-bottom: 10px;
  }

  button {
    padding: 12px 16px;
    border-radius: 6px;
    border: none;
    background-color: ${({ theme }) => theme.colors.secondary};
    margin-top: 10px;
    color: ${({ theme }) => theme.colors.white};
  }
`;
