import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ReactComponent as DropIcon } from '@/assets/icons/nav-arrow-down.icon.svg';
import useOnClickOutside from '@/hooks/useClickOutside';
import { useSearchParams } from 'react-router-dom';

const options = [10, 20, 30, 50];

const TableRowsSelect = ({ onChange }) => {
  const [searchParams] = useSearchParams();
  const limit = searchParams.get('limit');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(limit);
  const ref = useRef(null);
  useOnClickOutside(ref, () => setIsOpen(false));

  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleOptionClick = useCallback(
    (option) => {
      setSelectedOption(option);
      setIsOpen(false);
      onChange(option);
    },
    [onChange]
  );

  return (
    <DropdownContainer ref={ref}>
      {/* <label>Rows per page:</label> */}
      <DropdownButton onClick={toggleDropdown} whileTap={{ scale: 0.95 }}>
        {selectedOption}
        <motion.span animate={{ rotate: isOpen ? 180 : 0 }} transition={{ duration: 0.2 }}>
          <DropIcon />
        </motion.span>
      </DropdownButton>
      {isOpen && (
        <DropdownMenu
          initial={{ opacity: 0, scaleY: 0 }}
          animate={{ opacity: 1, scaleY: 1 }}
          exit={{ opacity: 0, scaleY: 0 }}
          transition={{ duration: 0.2 }}>
          {options.map((option) => (
            <DropdownMenuItem
              key={option}
              onClick={() => handleOptionClick(option)}
              className={option === selectedOption ? 'selected' : ''}
              whileHover={{ scale: 1.05 }}>
              {option}
            </DropdownMenuItem>
          ))}
        </DropdownMenu>
      )}
    </DropdownContainer>
  );
};

export default TableRowsSelect;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  font-family: Arial, sans-serif;
  padding-bottom: 0;
`;

const DropdownButton = styled(motion.button)`
  width: 70px;
  height: 40px;
  padding: 10px 6px;
  padding-bottom: 0;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme?.colors?.background};
  font-size: 1rem;
  font-weight: 500;

  span {
    color: ${({ theme }) => theme?.colors?.secondary};
    padding: 0;
    margin: 0;
  }

  svg {
    width: 20px;
    height: 20px;
    stroke: ${({ theme }) => theme?.colors?.secondary};
    margin-right: 5px;
  }
`;

const DropdownMenu = styled(motion.ul)`
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0px 13.9303px 41.7909px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 0;
  list-style: none;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
`;

const DropdownMenuItem = styled(motion.li)`
  padding: 10px 6px;
  cursor: pointer;
  color: ${({ theme }) => theme?.colors?.secondary};
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #f0f0f0;
  }
  &.selected {
    background-color: #e7f3ff;
    font-weight: 600;
  }
`;
