import { styled } from 'styled-components';
import { format } from 'date-fns';
import { motion, AnimatePresence } from 'framer-motion';

import { ReactComponent as SearchIcon } from '@/assets/icons/search.icon.svg';
import { ReactComponent as ArrowIcon } from '@/assets/icons/arrow-tr.icon.svg';
import { ReactComponent as FilterIcon } from '@/assets/icons/filter.icon.svg';
import { ReactComponent as ArrowUpIcon } from '@/assets/icons/arrow-up.icon.svg';
import { ReactComponent as ArrowDownIcon } from '@/assets/icons/arrow-down.icon.svg';
import { ReactComponent as MoreIcon } from '@/assets/icons/more.icon.svg';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSortableData } from '@/hooks/useSortableData';
import { useRef, useState } from 'react';
import useOnClickOutside from '@/hooks/useClickOutside';
import TableLoader from '@/loaders/TableLoader';
import { fetchAccounts, getModules } from '@/api/accountsApi';
import SuspendModal from './SuspendModal';
import DeleteModal from './DeleteModal';
import AdminDetailsModal from './AdminDetailsModal';
import { UpdateAccountModal } from './UpdateAccountModal';
import { usePermissions } from '@/hooks/usePermissions';
import toast from 'react-hot-toast';
import { formatInTimeZone } from 'date-fns-tz';

function AccountsTable() {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [showMenu, setShowMenu] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showSuspendModal, setShowSuspendModal] = useState(false);
  const [showAdminDetailsModal, setShowAdminDetailsModal] = useState(false);

  const ref = useRef();
  useOnClickOutside(ref, () => setShowMenu(false));

  const { hasPermission, superAdmin } = usePermissions();
  const isASuperAdmin = superAdmin(true);
  const canUpdateSettings = hasPermission('settings', 'update');
  const canDeleteSettings = hasPermission('settings', 'delete');

  const accounts = useQuery({
    queryKey: ['accounts', { page: 1, limit: 10 }],
    queryFn: fetchAccounts,
    keepPreviousData: true
  });

  const modules = useQuery({
    queryKey: ['modules'],
    queryFn: getModules,
  })

  // Sort table
  const { items, requestSort, sortConfig } = useSortableData(accounts?.data?.data?.accounts || []);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  // Fetch next page
  // const fetchNextPage = () => {

  // };

  // Fetch previous page
  // const fetchPrevPage = () => {

  // };
  const handleEdit = (id) => {
    const isAllowed = canUpdateSettings || isASuperAdmin;
    if (!isAllowed) {
      toast.error('You do not have permission to update');
      return;
    } else {
      setSelectedId(id);
      setShowUpdateModal(true);
    }
  }

  const handleSuspend = (id) => {
    const isAllowed = canUpdateSettings || isASuperAdmin;
    if (!isAllowed) {
      toast.error('You do not have permission to update');
      return;
    } else {
      setSelectedId(id);
      setShowSuspendModal(true);
    }
  }

  const handleDelete = (id) => {
    const isAllowed = canDeleteSettings || isASuperAdmin;
    if (!isAllowed) {
      toast.error('You do not have permission to update');
      return;
    } else {
      setSelectedId(id);
      setShowDeleteModal(true);
    }
  }

  if (accounts.isFetching) {
    return <TableLoader />;
  }

  return (
    <AccountsTableView>
      <TableHeader>
        <SearchView>
          <SearchIcon />
          <input type="search" placeholder="Search accounts" />
        </SearchView>
        <FilterView>
          <button className="export">
            <ArrowIcon /> Export
          </button>
          <button className="filter">
            <FilterIcon /> Filter
          </button>
        </FilterView>
      </TableHeader>
      <TableHead>
        <div
          className={`name ${getClassNamesFor('firstName')}`}
          onClick={() => requestSort('firstName')}>
          <span>Name</span>
          <span className="icon">
            <ArrowUpIcon className="up" />
            <ArrowDownIcon className="down" />
          </span>
        </div>
        <div className={`email ${getClassNamesFor('email')}`} onClick={() => requestSort('email')}>
          <span>Email</span>
          <span className="icon">
            <ArrowUpIcon className="up" />
            <ArrowDownIcon className="down" />
          </span>
        </div>
        <div className={`role ${getClassNamesFor('role')}`} onClick={() => requestSort('role')}>
          <span>Role</span>
          <span className="icon">
            <ArrowUpIcon className="up" />
            <ArrowDownIcon className="down" />
          </span>
        </div>
        <div
          className={`status ${getClassNamesFor('isVerified')}`}
          onClick={() => requestSort('isVerified')}>
          <span>Status</span>
          <span className="icon">
            <ArrowUpIcon className="up" />
            <ArrowDownIcon className="down" />
          </span>
        </div>
        <div
          className={`date ${getClassNamesFor('createdAt')}`}
          onClick={() => requestSort('createdAt')}>
          <span>Date</span>
          <span className="icon">
            <ArrowUpIcon className="up" />
            <ArrowDownIcon className="down" />
          </span>
        </div>
        <div className="actions"></div>
      </TableHead>

      <TableBody>
        {items.map((item, i) => {
          return (
            <div key={item?.id} className="item">
              <div className="name">
                <span>
                  {item.firstName || '-'} {item.lastName || '-'}
                </span>
              </div>
              <div className="email">
                <span>{item.email || '-'}</span>
              </div>
              <div className="role">
                <span>{item.role || '-'}</span>
              </div>
              <div className="status">
                {item.status === 'active' ? (
                  <div className="verified">
                    <span className="dot"></span> Active
                  </div>
                ) : (
                  <div className="pending">
                    <span className="dot"></span> Inactive
                  </div>
                )}
              </div>
              <div className="date">
                <span>{formatInTimeZone(new Date(item.createdAt), 'UTC', 'do MMM, yyyy')}</span>
              </div>
              <div className="actions">
                <button
                  className="menu-icon"
                  onClick={() => {
                    setShowMenu(true);
                    setCurrentIndex(i);
                  }}>
                  <MoreIcon />
                </button>

                {/* ===== Dropdown Menu ====== */}
                <AnimatePresence>
                  {showMenu && currentIndex === i ? (
                    <motion.div
                      className="menu-actions"
                      ref={ref}
                      initial={{ y: -20 }}
                      animate={{ y: 0 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}>
                      <button
                        onClick={() => {
                          setSelectedId(item?.id);
                          setShowAdminDetailsModal(true);
                        }}>
                        <span>View admin</span>
                      </button>
                      <button
                        onClick={() => {
                          handleEdit(item?.id);
                        }}>
                        <span>Edit admin</span>
                      </button>
                      {item?.status === 'active' && (
                        <button
                          onClick={() => {
                            handleSuspend(item?.id);
                          }}>
                          <span>Suspend admin</span>
                        </button>
                      )}
                      <button
                        onClick={() => {
                          handleDelete(item?.id);
                        }}>
                        <span>Delete admin</span>
                      </button>
                    </motion.div>
                  ) : null}
                </AnimatePresence>
              </div>
            </div>
          );
        })}
      </TableBody>

      <AdminDetailsModal 
        showModal={showAdminDetailsModal}
        setShowModal={setShowAdminDetailsModal}
        selectedId={selectedId}
        setSeledtedId={setSelectedId}
      />

      <UpdateAccountModal 
        showModal={showUpdateModal}
        setShowModal={setShowUpdateModal}
        selectedId={selectedId}
      />

      <SuspendModal 
        showModal={showSuspendModal}
        setShowModal={setShowSuspendModal}
        selectedId={selectedId}
        setSeledtedId={setSelectedId}
      />

      <DeleteModal 
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        selectedId={selectedId}
        setSeledtedId={setSelectedId}
      />
    </AccountsTableView>
  );
}

export default AccountsTable;

const AccountsTableView = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 40px;
`;

const TableHeader = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.line};
  border-left: none;
  border-right: none;
`;

const SearchView = styled.div`
  width: 40%;
  height: 40px;
  display: flex;
  align-items: center;

  input {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    margin-left: 10px;
    font-weight: 400;

    &::placeholder {
      font-size: 1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.placeholder};
    }
  }
`;

const FilterView = styled.div`
  width: 30%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    width: 120px;
    height: 100%;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 10px;
    }
  }
`;

const TableHead = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.line};

  div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    height: 100%;

    span {
      font-size: 0.8rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.black};
      /* text-transform: uppercase; */
    }

    .icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 4px;
      width: 10px;
    }
  }

  .ascending {
    color: ${({ theme }) => theme.colors.black};

    .up path {
      stroke: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .descending {
    color: ${({ theme }) => theme.colors.black};

    .down path {
      stroke: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .name {
    flex: 1;
  }

  .email {
    flex: 1;
  }

  .role {
    flex: 0.5;
  }

  .status {
    flex: 0.5;
  }

  .date {
    flex: 0.5;
  }

  .actions {
    flex: 0.5;
  }
`;

const TableBody = styled.div`
  width: 100%;

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    div {
      flex: 1;
      flex-wrap: wrap;

      span {
        font-size: 0.75rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.secondary};
        text-transform: capitalize;
      }
    }

    .name {
      flex: 1;
    }

    .email {
      flex: 1;
    }

    .role {
      flex: 0.5;
    }

    .status {
      flex: 0.5;
    }

    .date {
      flex: 0.5;
    }

    .actions {
      flex: 0.5;
    }

    .status {
      flex: 0.5;

      .verified {
        color: ${({ theme }) => theme.colors?.success};
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 0.75rem;

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.colors?.success};
          margin-right: 4px;
        }
      }

      .pending {
        color: ${({ theme }) => theme.colors?.error_200};
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 0.75rem;

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.colors?.error_200};
          margin-right: 4px;
        }
      }
    }
    .actions {
      flex: 0.5;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      svg {
        cursor: pointer;

        path {
          stroke: ${({ theme }) => theme.colors?.secondary};
        }
      }

      .menu-actions {
        position: absolute;
        top: 22px;
        right: 0px;
        background-color: ${({ theme }) => theme.colors.white};
        box-shadow:
          0px 0px 2px rgba(125, 125, 125, 0.1),
          0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        z-index: 10;
        width: 180px;
        border: none;

        button {
          width: 100%;
          padding: 16px 12px;
          font-size: 0.875rem;
          background-color: transparent;
          transition: all 0.2s ease-in-out;
          border: none;
          outline: none;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &:hover {
            background-color: ${({ theme }) => theme.colors.layer3};
          }

          span {
            color: ${({ theme }) => theme.colors.activeTitle};
            width: calc(100% - 35px);
            display: flex;
            justify-content: flex-start;
            text-align: left;
            font-weight: 400;
          }
        }
      }
    }
  }
`;
