import PageHeader from '@/components/PageHeader';
import { styled } from 'styled-components';
import ComplianceTable from './components/ComplianceTable';
import withAuthentication from '@/hooks/withAuthentication';
import { usePermissions } from '@/hooks/usePermissions';
import { EmptyState } from '@/components/EmptyState';
import ComplianceMetrics from './components/ComplianceMetrics';

function Compliance() {
  const { role, hasPermission, superAdmin } = usePermissions();
  const canReadCompliance = hasPermission('compliance', 'read');
  const isASuperAdmin = superAdmin(true);
  const isRoleAdmin = role('Admin');


  return (
    <>
      <PageHeader title="Compliance" subtitle="View and manage all compliances" />
      <ComplianceView>
        {canReadCompliance || isASuperAdmin || isRoleAdmin ? (
          <div>
            <ComplianceMetrics />
            <ComplianceTable />
          </div>
        ) : <EmptyState />}
      </ComplianceView>
    </>
  );
}

export default withAuthentication(Compliance);

const ComplianceView = styled.div`
  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 120px;
  overflow-x: hidden;
  margin-left: 18%;
`;
