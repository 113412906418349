import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { useNetworkState } from 'react-use';
import { toast } from 'react-hot-toast';
import Sidebar from './Sidebar';

const Layout = () => {
  const state = useNetworkState();

  useEffect(() => {
    if (!state.online) {
      toast.error('You are offline, please check your internet connection');
    }
  }, [state]);

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  return (
    <View>
      <Sidebar />
      <Outlet />
    </View>
  );
};

export default Layout;

const View = styled.section`
  width: 100vw;
  height: 100vh;
  display: flex;

  .nav {
    width: 20%;
  }
`;
