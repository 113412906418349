import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import { useEffect, useState } from 'react';

import { ReactComponent as CloseIcon } from '@/assets/icons/cancel.icon.svg';
import { ReactComponent as VerifiedIcon } from '@/assets/icons/verified.icon.svg';
import { ReactComponent as NextIcon } from '@/assets/icons/nav-right.icon.svg';
import { ReactComponent as PrevIcon } from '@/assets/icons/nav-left.icon.svg';
import { ReactComponent as FileIcon } from '@/assets/icons/file.icon.svg';
import Button from '@/components/Button';
import { useNavigate, useParams } from 'react-router-dom';
import {
  approveBillFinance,
  fetchLoanDetail,
  fetchUserTransactions,
  rejectBillFinance
} from '@/api/billFinancingApi';
import { numberWithCommas } from '@/utils/helpers';
import { format } from 'date-fns';
import FileModal from './FileModal';
import TableLoader from '@/loaders/TableLoader';
import PageLoader from '@/loaders/PageLoader';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import axios from 'axios';
import fileDownload from 'js-file-download';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { usePermissions } from '@/hooks/usePermissions';
import { formatInTimeZone } from 'date-fns-tz';

function ApproveModal({ showApproveModal, setShowApproveModal, selectedItemId, selectedUserId }) {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const queryClient = useQueryClient();
  const [showProof, setShowProof] = useState(false);
  const [showCertificate, setShowCertificate] = useState(false);
  const [showMemorandum, setShowMemorandum] = useState(false);
  const [showSupportingDocs, setShowSupportingDocs] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const PAGE_LIMIT = 10;

  const { hasPermission, superAdmin } = usePermissions();
  const isASuperAdmin = superAdmin(true);
  const canUpdatePurchaseFinance = hasPermission('purchase-financing', 'update');

  const details = useQuery({
    queryKey: ['details', selectedItemId],
    queryFn: fetchLoanDetail
  });

  const transactions = useQuery({
    queryKey: ['transactions', selectedUserId, { page, limit: PAGE_LIMIT }],
    queryFn: fetchUserTransactions
  });

  const approveFinance = useMutation({
    mutationFn: ({ id }) => approveBillFinance(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['pending']);
      setShowApproveModal(false);
      navigate('/purchase-financing?tab=purchase-financing&view=approved');
      toast.success('Bill finance approved');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const rejectFinance = useMutation({
    mutationFn: ({ id }) => rejectBillFinance(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['pending']);
      setShowApproveModal(false);
      navigate('/purchase-financing?tab=purchase-financing&view=denied');
      toast.error('Bill finance rejected, Please upload the necessary documents');
    }
  });

  const handleApproval = () => {
    approveFinance.mutate({ id: selectedItemId });
  };

  const handleRejection = () => {
    rejectFinance.mutate({ id: selectedItemId });
  };

  const detail = details?.data?.data;
  const transaction = transactions?.data?.data.transactions;

  const handleApprove = () => {
    const isAllowed = canUpdatePurchaseFinance || isASuperAdmin;
    if (!isAllowed) {
      toast.error('You do not have permission to update');
      return;
    } else {
      handleApproval();
    }
  };

  const handleReject = () => {
    const isAllowed = canUpdatePurchaseFinance || isASuperAdmin;
    if (!isAllowed) {
      toast.error('You do not have permission to update');
      return;
    } else {
      handleRejection();
    }
  };

  //Download pdf
  const handleFileDownload = (url, filename) => {
    console.log('Attempting to download file:', url);
    const parts = filename.split('/');
    const newFileName = parts[parts.length - 1];
    axios
      .get(url, {
        responseType: 'blob'
      })
      .then((res) => {
        fileDownload(res.data, newFileName);
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
  };

  const downloadCertificate = () => {
    setShowApproveModal(false);
    handleFileDownload(detail?.user?.compliance[0]?.certificateUrl, 'Certificate');
  };

  const downloadMemorandum = () => {
    setShowApproveModal(false);
    handleFileDownload(detail?.user?.compliance[0]?.memorandumUrl, 'Memorandum');
  };

  const downloadProof = () => {
    setShowApproveModal(false);
    handleFileDownload(detail?.user?.compliance[0]?.proofOfBusinessUrl, 'Proof-of-Business');
  };

  const downloadSupportingDoc = (index) => {
    setShowApproveModal(false);
    const supportingDocUrl = detail.billFinanceDocument[index].fileUrl;
    handleFileDownload([supportingDocUrl], 'Supporting-Document');
  };

  //Paginate
  const fetchNextPage = () => {
    const totalPage = Math.ceil(transactions?.data?.data?.meta?.totalItems / PAGE_LIMIT);
    if (page < totalPage) {
      setPage(page + 1);
    }
  };

  const fetchPrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  if (details.isFetching) {
    return <PageLoader />;
  }

  return (
    <AnimatePresence>
      {showApproveModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======== Content ======== */}
            <ModalContent>
              <div>
                <div className="header">
                  <button
                    type="button"
                    onClick={() => {
                      setShowApproveModal(false);
                    }}>
                    <CloseIcon />
                  </button>
                </div>

                <div className="heading">
                  <div className="status">
                    <h3>Compliance status</h3>
                    <p className="verified">
                      Approved{' '}
                      <span>
                        <VerifiedIcon />
                      </span>
                    </p>
                  </div>

                  <div className="summary">
                    <h3>Applicant's Summary</h3>

                    <div className="summary-details">
                      <div className="detail">
                        <p>Business Name:</p>
                        <span>{detail?.billFinanceDetail[0]?.businessName}</span>
                      </div>
                      <div className="detail">
                        <p>Business Address:</p>
                        <span>{detail?.billFinanceDetail[0]?.businessAddress}</span>
                      </div>
                      <div className="detail">
                        <p>Business Email:</p>
                        <span>{detail?.billFinanceDetail[0]?.email}</span>
                      </div>
                      <div className="detail">
                        <p>Designation:</p>
                        <span>{detail?.billFinanceDetail[0]?.designation || '-'}</span>
                      </div>
                    </div>
                  </div>

                  <div className="documents">
                    <h3>Supporting documents</h3>

                    <div className="documents-container">
                      {detail.billFinanceDocument.map((doc, i) => {
                        return (
                          <div className="document">
                            <div className="icon">
                              <FileIcon />
                            </div>

                            <div className="details">
                              <p className="name">
                                {doc?.fileUrl
                                  ? doc?.fileUrl.substring(doc?.fileUrl.lastIndexOf('/') + 1)
                                  : '--'}
                              </p>

                              <div className="btn-text">
                                <button
                                  onClick={() => {
                                    setCurrentIndex(i);
                                    setShowSupportingDocs(true);
                                  }}>
                                  View
                                </button>
                                <button
                                  className="download"
                                  onClick={() => downloadSupportingDoc(i)}>
                                  Download
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    {/* ===== Compliance Documents ====== */}
                    <h3 style={{ marginTop: '20px' }}>Compliance documents</h3>
                    <div className="documents-container">
                      <div className="document">
                        <div className="icon">
                          <FileIcon />
                        </div>
                        <div className="details">
                          <p className="name">
                            {detail?.user?.compliance[0]?.certificateUrl
                              ? detail?.user?.compliance[0]?.certificateUrl.substring(
                                  detail?.user?.compliance[0]?.certificateUrl.lastIndexOf('/') + 1
                                )
                              : '--'}
                          </p>

                          <div className="btn-text">
                            <button
                              onClick={() => {
                                setShowCertificate(true);
                              }}>
                              View
                            </button>
                            <button className="download" onClick={downloadCertificate}>
                              Download
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="document">
                        <div className="icon">
                          <FileIcon />
                        </div>
                        <div className="details">
                          <p className="name">
                            {detail?.user?.compliance[0]?.memorandumUrl
                              ? detail?.user?.compliance[0]?.memorandumUrl.substring(
                                  detail?.user?.compliance[0]?.memorandumUrl.lastIndexOf('/') + 1
                                )
                              : '--'}
                          </p>

                          <div className="btn-text">
                            <button
                              onClick={() => {
                                setShowMemorandum(true);
                              }}>
                              View
                            </button>
                            <button className="download" onClick={downloadMemorandum}>
                              Download
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="document">
                        <div className="icon">
                          <FileIcon />
                        </div>
                        <div className="details">
                          <p className="name">
                            {detail?.user?.compliance[0]?.proofOfBusinessUrl
                              ? detail?.user?.compliance[0]?.proofOfBusinessUrl.substring(
                                  detail?.user?.compliance[0]?.proofOfBusinessUrl.lastIndexOf('/') +
                                    1
                                )
                              : '--'}
                          </p>

                          <div className="btn-text">
                            <button
                              onClick={() => {
                                setShowProof(true);
                              }}>
                              View
                            </button>
                            <button className="download" onClick={downloadProof}>
                              Download
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ContentView>
                <div className="table-view">
                  <p>All transactions</p>
                  {transactions.isFetching ? (
                    <TableLoader />
                  ) : (
                    <div>
                      <TableHead>
                        <div className="type">
                          <span>Remark</span>
                        </div>
                        <div className="tin">
                          <span>Trx Ref.</span>
                        </div>
                        <div className="amount">
                          <span>Amount</span>
                        </div>
                        <div className="date">
                          <span>Date Paid</span>
                        </div>
                        <div className="reg">
                          <span>Type</span>
                        </div>
                        <div className="status">
                          <span>Status</span>
                        </div>
                      </TableHead>
                      <TableBody>
                        {transaction?.map((item) => {
                          return (
                            <div key={item?.id} className="item">
                              <div className="type">
                                <span>{item?.remark || '--'}</span>
                              </div>
                              <div className="tin">
                                <span>{item?.identifier}</span>
                              </div>
                              <div className="amount">
                                <span>₦{numberWithCommas(item?.amount)}</span>
                              </div>
                              <div className="date">
                                <span>
                                  {item?.createdAt
                                    ? formatInTimeZone(new Date(item?.createdAt), 'UTC', 'do MMM, yyyy')
                                    : '--'}
                                </span>
                              </div>
                              <div className="reg">
                                <span>{item?.event}</span>
                              </div>
                              <div className="status">
                                <Status status={item?.status}>
                                  <span className="dot"></span> {item?.status || '-'}
                                </Status>
                              </div>
                            </div>
                          );
                        })}
                      </TableBody>
                    </div>
                  )}

                  {/* ===== Pagination ====== */}
                  <PaginationView>
                    <div className="total">
                      {/* <span>{invoices?.data?.data?.meta?.size} results</span> */}
                    </div>
                    <div className="paginate">
                      <button className="prev" onClick={fetchPrevPage}>
                        <PrevIcon />
                      </button>
                      <span>
                        Page {page} of{' '}
                        {Math.ceil(transactions?.data?.data?.meta?.totalItems / PAGE_LIMIT)}
                      </span>
                      <button className="next" onClick={fetchNextPage}>
                        <NextIcon />
                      </button>
                    </div>
                  </PaginationView>

                  <div className="btn-flex">
                    <Button className="approve" onClick={() => handleApprove()}>
                      {approveFinance.isLoading ? (
                        <Oval height={30} width={30} color="#fff" secondaryColor="#ddd" />
                      ) : (
                        'Approve'
                      )}
                    </Button>
                    <Button className="deny" onClick={() => handleReject()}>
                      {rejectFinance.isLoading ? (
                        <Oval height={30} width={30} color="#fff" secondaryColor="#ddd" />
                      ) : (
                        'Deny'
                      )}
                    </Button>
                  </div>
                </div>
              </ContentView>
            </ModalContent>
          </motion.div>
        </ModalView>
      )}

      {showSupportingDocs && currentIndex !== -1 && (
        <FileModal showModal={showSupportingDocs}>
          <div className="header">
            <button type="button" onClick={() => setShowSupportingDocs(false)}>
              <CloseIcon />
            </button>
          </div>
          <div className="content">
            <DocViewer
              style={{ width: 500, height: 600 }}
              pluginRenderers={DocViewerRenderers}
              config={{ header: { disableHeader: true } }}
              documents={[{ uri: detail?.billFinanceDocument[currentIndex]?.fileUrl }]}
            />
          </div>
        </FileModal>
      )}

      {showCertificate && (
        <FileModal showModal={showCertificate}>
          <div className="header">
            <button type="button" onClick={() => setShowCertificate(false)}>
              <CloseIcon />
            </button>
          </div>
          <div className="content">
            {detail?.user?.compliance[0]?.certificateUrl ? (
              <DocViewer
                style={{ width: 500, height: 600 }}
                pluginRenderers={DocViewerRenderers}
                config={{ header: { disableHeader: true } }}
                documents={[{ uri: detail?.user?.compliance[0]?.certificateUrl }]}
              />
            ) : (
              <p>No File to display</p>
            )}
          </div>
        </FileModal>
      )}

      {showMemorandum && (
        <FileModal showModal={showMemorandum}>
          <div className="header">
            <button type="button" onClick={() => setShowMemorandum(false)}>
              <CloseIcon />
            </button>
          </div>
          <div className="content">
            {detail?.user?.compliance[0]?.memorandumUrl ? (
              <DocViewer
                style={{ width: 500, height: 600 }}
                pluginRenderers={DocViewerRenderers}
                config={{ header: { disableHeader: true } }}
                documents={[{ uri: detail?.user?.compliance[0]?.memorandumUrl }]}
              />
            ) : (
              <p>No File to display</p>
            )}
          </div>
        </FileModal>
      )}

      {showProof && (
        <FileModal showModal={showProof}>
          <div className="header">
            <button type="button" onClick={() => setShowProof(false)}>
              <CloseIcon />
            </button>
          </div>
          <div className="content">
            {detail?.user?.compliance[0]?.proofOfBusinessUrl ? (
              <DocViewer
                style={{ width: 500, height: 600 }}
                pluginRenderers={DocViewerRenderers}
                config={{ header: { disableHeader: true } }}
                documents={[{ uri: detail?.user?.compliance[0]?.proofOfBusinessUrl }]}
              />
            ) : (
              <p>No File to display</p>
            )}
          </div>
        </FileModal>
      )}
    </AnimatePresence>
  );
}

export default ApproveModal;

const getStatusColor = (status) => {
  switch (status) {
    case 'successful':
      return '#16A34A';
    case 'pending':
      return '#F4B740';
    case 'initiated':
      return '#F4B740';
    case 'declined':
      return '#B91C1C';

    default:
  }
};

const Status = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.75rem;
  text-transform: capitalize;
  color: ${({ status }) => getStatusColor(status)};

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 4px;
    background-color: ${({ status }) => getStatusColor(status)};
  }
`;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9009;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 28px;
    width: 80vw;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    max-height: 90%;
    overflow-y: scroll;
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    h3 {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .heading {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .status {
      p {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 24px;
        font-weight: 600;
        margin: 0.5rem 0;
        color: ${({ theme }) => theme.colors.secondary};
      }
    }

    .documents {
      margin-bottom: 2rem;
      max-width: 100%;

      .documents-container {
        max-width: 100%;
        display: flex;
        align-items: center;
        margin-top: 1rem;
        gap: 1.2rem;
        flex-wrap: wrap;

        .document {
          display: flex;
          align-items: center;
          gap: 0.75rem;
          padding: 1rem 0.75rem;
          border-radius: 15px;
          background-color: ${({ theme }) => theme.colors.document};

          .details {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;

            .name {
              font-size: 10px;
              font-weight: 600;
              color: ${({ theme }) => theme.colors.secondary};
            }

            .size {
              font-size: 10px;
              font-weight: 400;
              color: ${({ theme }) => theme.colors.body};
            }
          }

          .btn-text {
            display: flex;
          }

          .download {
            /* color: ${({ theme }) => theme.colors.secondary}; */
          }

          button {
            font-size: 10px;
            font-weight: 600;
            color: ${({ theme }) => theme.colors.primary};
            background-color: none;
            border-radius: 15px;
            padding: 0.25rem 0.5rem;
            border: 1px solid ${({ theme }) => theme.colors.primary};
            border: none;
          }
        }
      }
    }

    .summary {
      .summary-details {
        padding: 1rem 0;

        .detail {
          display: flex;
          padding: 0.3rem 0;
          align-items: center;
          gap: 0.5rem;

          p {
            font-size: 14px;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.secondary};
          }

          span {
            font-size: 14px;
            font-weight: 600;
            color: ${({ theme }) => theme.colors.secondary};
          }
        }
      }
    }
  }
`;

const ContentView = styled.div`
  width: 100%;

  .content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 30px;

    .icon {
      path {
        stroke: ${(props) => props.theme.colors?.warning};
      }
    }

    .green-icon {
      path {
        stroke: ${(props) => props.theme.colors?.warning};
      }
    }

    h3 {
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 10px;
      color: ${(props) => props.theme.colors?.secondary};
      margin-top: 30px;
    }

    p {
      font-size: 0.9rem;
      font-weight: 400;
      color: ${(props) => props.theme.colors?.darkGray};
      margin-bottom: 20px;
      text-align: center;
      max-width: 80%;
      line-height: 19px;
    }

    .delete {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      background-color: ${(props) => props.theme.colors?.error_200};
      height: 64px;
      border-radius: 16px;
      color: ${(props) => props.theme.colors?.white};
      font-weight: 500;
    }

    .continue {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      background-color: ${(props) => props.theme.colors?.primary};
      height: 64px;
      border-radius: 16px;
      color: ${(props) => props.theme.colors?.white};
      font-weight: 500;
    }
  }

  .table-view {
    p {
      font-size: 24px;
      font-weight: 600;
      margin: 0.5rem 0;
      color: ${({ theme }) => theme.colors.secondary};
    }

    .btn-flex {
      display: flex;
      margin-top: 3rem;
      /* margin-bottom: 1rem; */
      gap: 1rem;

      .approve {
        width: 30%;
        border-radius: 18px;
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.primary};
      }

      .deny {
        width: 30%;
        border-radius: 18px;
        color: ${({ theme }) => theme.colors.error};
        background-color: ${({ theme }) => theme.colors.white};
        border: 1px solid ${({ theme }) => theme.colors.error};
      }
    }
  }
`;

const TableHead = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.line};

  div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    height: 100%;

    span {
      font-size: 0.8rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.black};
      /* text-transform: uppercase; */
    }

    .icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 4px;
      width: 10px;
    }
  }

  .ascending {
    color: ${({ theme }) => theme.colors.black};

    .up path {
      stroke: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .descending {
    color: ${({ theme }) => theme.colors.black};

    .down path {
      stroke: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .type {
    flex: 1;
  }

  .amount {
    flex: 0.5;
  }

  .year {
    flex: 0.8;
  }

  .reg {
    flex: 0.3;
  }

  .tin {
    flex: 1;
  }

  .status {
    flex: 0.5;
  }

  .date {
    flex: 0.5;
  }

  .actions {
    flex: 0.5;
  }
`;

const TableBody = styled.div`
  width: 100%;

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    div {
      flex: 1;
      flex-wrap: wrap;

      span {
        font-size: 0.75rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.secondary};
        text-transform: capitalize;
      }
    }

    .type {
      flex: 1;
    }

    .amount {
      flex: 0.5;
    }

    .year {
      flex: 0.8;
    }

    .reg {
      flex: 0.3;
    }

    .tin {
      flex: 1;
    }

    .status {
      flex: 0.5;

      .verified {
        color: ${({ theme }) => theme.colors?.success};
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 0.75rem;

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.colors?.success};
          margin-right: 4px;
        }
      }

      .pending {
        color: ${({ theme }) => theme.colors?.warning};
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 0.75rem;
        text-transform: capitalize;

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.colors?.warning};
          margin-right: 4px;
        }
      }
    }

    .date {
      flex: 0.5;
      overflow: hidden;
    }

    .actions {
      flex: 0.5;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      svg {
        cursor: pointer;

        path {
          stroke: ${({ theme }) => theme.colors?.secondary};
        }
      }

      .menu-actions {
        position: absolute;
        top: 22px;
        right: 0px;
        background-color: ${({ theme }) => theme.colors.white};
        box-shadow:
          0px 0px 2px rgba(125, 125, 125, 0.1),
          0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        z-index: 10;
        width: 180px;
        border: none;

        button {
          width: 100%;
          padding: 16px 12px;
          font-size: 0.875rem;
          background-color: transparent;
          transition: all 0.2s ease-in-out;
          border: none;
          outline: none;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &:hover {
            background-color: ${({ theme }) => theme.colors.layer3};
          }

          span {
            color: ${({ theme }) => theme.colors.activeTitle};
            width: calc(100% - 35px);
            display: flex;
            justify-content: flex-start;
            text-align: left;
            font-weight: 400;
          }
        }
      }
    }
  }
`;

const PaginationView = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .total {
    span {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }

  .paginate {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;

    button {
      width: 30px;
      height: 30px;
      border: none;
      outline: none;
      background-color: ${({ theme }) => theme.colors?.layer3};
      color: ${({ theme }) => theme.colors?.secondary};
      font-size: 0.875rem;
      font-weight: 500;
      border-radius: 50%;

      &:hover {
        background-color: ${({ theme }) => theme.colors?.layer2};
      }
    }

    span {
      margin: 0 10px;
      font-size: 0.69rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }
`;
