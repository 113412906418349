import { fetchLoanInterests } from '@/api/lendersApi'
import useDebounce from '@/hooks/useDebounce';
import { useSortableData } from '@/hooks/useSortableData';
import TableLoader from '@/loaders/TableLoader';
import { numberWithCommas } from '@/utils/helpers';
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import styled from 'styled-components';
import { ReactComponent as NextIcon } from '@/assets/icons/nav-right.icon.svg';
import { ReactComponent as PrevIcon } from '@/assets/icons/nav-left.icon.svg';
import { useSearchParams } from 'react-router-dom';
import TableRowsSelect from '@/components/TableRowSelect';
import { formatInTimeZone } from 'date-fns-tz';

export const LoanInterests = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const limit = parseInt(searchParams.get('limit')) || 10;
  const PAGE_LIMIT = 10;
  const debouncedsearch = useDebounce(search, 500);

  const interests = useQuery({
    queryKey: ['interests', { page, limit, search: debouncedsearch }],
    queryFn: fetchLoanInterests
  })

  const { items, requestSort, sortConfig } = useSortableData(interests?.data?.data?.requests || []);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  }

  const handleRowsPerPageChange = (value) => {
    setSearchParams({ tab: 'interests', page, limit: value });
  };

  //Paginate
  const fetchNextPage = () => {
    const totalPage = Math.ceil(interests.data?.data?.meta?.totalItems / limit);
    // const totalPage = Math.ceil(interests.data?.data?.meta?.totalItems / PAGE_LIMIT);
    if (page < totalPage) {
      setPage(page + 1)
    }
  }

  const fetchPrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  }

  if (interests.isFetching) {
    return <TableLoader />
  }

  return (
    <LoanInterestsView>
      <TableHead>
        <div className='provider'>
          <span>Type</span>
        </div>
        <div className='provider'>
          <span>Tenure</span>
        </div>
        {/* <div className='channel'>
          <span>Company</span>
        </div>  */}
        <div className='amount'>
          <span>Loan Amt.</span>
        </div>
        <div className='amount'>
          <span>Interest</span>
        </div>
        <div className='amount'>
          <span>Amt.Paid</span>
        </div>
        <div className='amount'>
          <span>Balance</span>
        </div>
        {/*<div className='remark'>
          <span>Remark</span>
        </div>
        <div className='status'>
          <span>Status</span>
        </div>*/}
        <div className='created'>
          <span>Date created</span>
        </div> 
        {/* <div className='updated'>
          <span>Date updated</span>
        </div> */}
      </TableHead>
      <TableBody>
        {items.map((item) => {
          return (
            <div key={item?.id} className="item">
              <div className="provider">
                <span>{item?.loan?.type.replace(/_/g, ' ')}</span>
              </div>
              <div className="provider">
                <span>
                  {item?.loan?.tenure} {item?.loan?.tenure === 1 ? 'day' : 'days'}
                </span>
              </div>
              {/*<div className='channel'>
                {/* <span>{item?.loan?.loanDetails[0]?.businessName || '-'}</span>
              </div>
              */}
              <div className="amount">
                <span>₦{numberWithCommas(item?.loan?.amount || '00.00')}</span>
              </div>
              <div className="amount">
                <span>₦{numberWithCommas(item?.loan?.interest || '00.00')}</span>
              </div>
              <div className="amount">
                <span>₦{numberWithCommas(item?.loan?.paid || '00.00')}</span>
              </div>
              <div className="amount">
                <span>₦{numberWithCommas(item?.loan?.balance || '00.00')}</span>
              </div>
              {/*<div className='event'>
                <span>{item?.event}</span>
              </div>
              <div className='remark'>
                <span>{item?.remark}</span>
              </div>
              <Status status={item?.status} className='status'>
                  <span className="dot"></span> {item?.status || '-'}
              </Status>*/}
              <div className="created">
                <span>{formatInTimeZone(new Date(item?.loan?.createdAt), 'UTC', 'do MMM, yyy')}</span>
              </div>
            </div>
          );
        })}
      </TableBody>

      {/* ===== Pagination ====== */}
      <PaginationView>
        <TableRowsSelect onChange={handleRowsPerPageChange} />
        <div className="paginate">
          <button className="prev" onClick={fetchPrevPage}>
            <PrevIcon />
          </button>
          <span>
            Page {page} of {Math.ceil(interests?.data?.data?.meta?.totalItems / limit)} 
            {/* Page {page} of {Math.ceil(interests?.data?.data?.meta?.totalItems / PAGE_LIMIT)}  */}
          </span>
          <button className="next" onClick={fetchNextPage}>
            <NextIcon />
          </button>
        </div>
      </PaginationView>
    </LoanInterestsView>
  )
}

const LoanInterestsView = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 80px;
  padding-bottom: 30px;
`;

const TableHead = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.line};
  border-bottom: 1px solid ${({ theme }) => theme.colors.line};

  div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    height: 100%;

    span {
      font-size: 0.8rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.black};
      /* text-transform: uppercase; */
    }

    .icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 4px;
      width: 10px;
    }
  }

  .ascending {
    color: ${({ theme }) => theme.colors.black};

    .up path {
      stroke: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .descending {
    color: ${({ theme }) => theme.colors.black};

    .down path {
      stroke: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .channel {
    flex: 0.8;
  }

  .event {
    flex: 0.3;
  }

  .provider {
    flex: 0.5;
  }

  .amount {
    flex: 0.5;
  }

  .remark {
    flex: 1;
  }

  .status {
    flex: 0.5;
  }

  .created {
    flex: 0.5;
  }

  .updated {
    flex: 0.5;
  }
`;

const TableBody = styled.div`
  width: 100%;

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    div {
      flex: 1;
      flex-wrap: wrap;

      span {
        font-size: 0.75rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.secondary};
        text-transform: capitalize;
      }
    }

    .channel {
      flex: 0.8;
    }

    .event {
      flex: 0.3;
    }

    .provider {
      flex: 0.5;
    }

    .amount {
      flex: 0.5;
    }

    .remark {
      flex: 1;
    }

    .status {
      flex: 0.5;
    }

    .created {
      flex: 0.5;
    }

    .updated {
      flex: 0.5;
    }

    .actions {
      /* flex: 0.5; */
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      svg {
        cursor: pointer;

        path {
          stroke: ${({ theme }) => theme.colors?.secondary};
        }
      }

      .menu-actions {
        position: absolute;
        top: 22px;
        right: 0px;
        background-color: ${({ theme }) => theme.colors.white};
        box-shadow:
          0px 0px 2px rgba(125, 125, 125, 0.1),
          0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        z-index: 10;
        width: 180px;
        border: none;

        button {
          width: 100%;
          padding: 16px 12px;
          font-size: 0.875rem;
          background-color: transparent;
          transition: all 0.2s ease-in-out;
          border: none;
          outline: none;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &:hover {
            background-color: ${({ theme }) => theme.colors.layer3};
          }

          span {
            color: ${({ theme }) => theme.colors.activeTitle};
            width: calc(100% - 35px);
            display: flex;
            justify-content: flex-start;
            text-align: left;
            font-weight: 400;
          }
        }
      }
    }
  }
`;

const PaginationView = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .total {
    span {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }

  .paginate {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    padding: 2rem 0;

    button {
      width: 40px;
      height: 40px;
      border: none;
      outline: none;
      background-color: ${({ theme }) => theme.colors?.layer3};
      color: ${({ theme }) => theme.colors?.secondary};
      font-size: 0.875rem;
      font-weight: 500;
      border-radius: 6px;

      &:hover {
        background-color: ${({ theme }) => theme.colors?.layer2};
      }
    }

    span {
      margin: 0 10px;
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }
`;