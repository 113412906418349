import api from './api';

export const fetchUsers = async ({ queryKey }) => {
  const [, { page, limit, search, orderBy, fetch }] = queryKey;
  let url = `/users?page=${page}&limit=${limit}`;
  
  if (search) {
    url += `&search=${search}`;
  }
  if (orderBy) {
    url += `&orderBy=${orderBy}`;
  }
  if (fetch) {
    url += `&fetch=${fetch}`;
  }

  const response = await api.get(url);
  return response.data;
};

export const fetchUser = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/users/${id}`);
  return response.data;
};

export const fetchUserData = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/users/${id}/data`);
  return response.data;
};

export const assignUserToPlan = async (data) => {
  const response = await api.post(`/subscription/users/list`, data);
  return response.data;
};

export const assignMultipleUsersToPlan = async (data) => {
  const response = await api.post(`/subscription/users/list/bulk`, data);
  return response.data;
};

// export const fetchUsersStats = async ({ queryKey }) => {
//   const [, { fetch }] = queryKey;
//   const response = await api.get(`/admin/users/metrics/stat?fetch=${fetch}`);
//   return response.data;
// }

export const fetchUsersStats = async ({ queryKey }) => {
  const [, { fetch, startDate, endDate }] = queryKey;

  // Build the query parameters
  let query = '';

  // Only add fetch if startDate and endDate are not provided
  if (!startDate && !endDate) {
    query += `fetch=${fetch}`;
  }

  // Add startDate and endDate to the query if provided
  if (startDate) {
    query += (query ? '&' : '') + `startDate=${startDate}`;
  }
  if (endDate) {
    query += (query ? '&' : '') + `endDate=${endDate}`;
  }

  const response = await api.get(`/users/metrics/stat?${query}`);
  return response.data;
};


export const exportUser = async (data) => {
  const response = await api.post(`/export/user`, data);
  return response.data;
}

export const deactivateUser = async (data) => {
  const response = await api.post(`/users/action/suspend`, data);
  return response.data;
}

export const activateUser = async (data) => {
  const response = await api.post(`/users/action/activate`, data);
  return response.data;
}

export const fetchUserActivity = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/users/activities/${id}`);
  return response.data;
}

export const fetchUserTransactionActivity = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/users/transaction/activities/${id}`);
  return response.data;
}

export const fetchUserTimeline = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/users/timeline/${id}`);
  return response.data;
}

// export const fetchUserActivationTime = async ({ queryKey }) => {
//   const [, { fetch }] = queryKey;
//   const response = await api.get(`/admin/users/metrics/stat/activation/time?fetch=${fetch}`);
//   return response.data;
// }

export const fetchUserActivationTime = async ({ queryKey }) => {
  const [, { fetch, startDate, endDate }] = queryKey;

  // Build the query parameters
  let query = '';

  // Only add fetch if startDate and endDate are not provided
  if (!startDate && !endDate) {
    query += `fetch=${fetch}`;
  }

  // Add startDate and endDate to the query if provided
  if (startDate) {
    query += (query ? '&' : '') + `startDate=${startDate}`;
  }
  if (endDate) {
    query += (query ? '&' : '') + `endDate=${endDate}`;
  }

  const response = await api.get(`/users/metrics/stat/activation/time?${query}`);
  return response.data;
};