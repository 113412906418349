import PageHeader from "@/components/PageHeader";
import withAuthentication from "@/hooks/withAuthentication";
import styled from "styled-components";
import VirtualAccountsTable from "./components/VirtualAccountsTable";
import { usePermissions } from "@/hooks/usePermissions";
import { EmptyState } from "@/components/EmptyState";

function VirtualAccounts () {
  const { hasPermission, superAdmin } = usePermissions();
  const isASuperAdmin = superAdmin(true);
  const canReadAccounts = hasPermission('virtual-account', 'read');

  return (
    <>
      <PageHeader title="Virtual Accounts" subtitle="View and manage virtual accounts" />
      <VirtualAccountsView>
        {canReadAccounts || isASuperAdmin ? (
          <VirtualAccountsTable />
        ) : (
          <EmptyState />
        )}
      </VirtualAccountsView>
    </>
  )
}

export default withAuthentication(VirtualAccounts);

const VirtualAccountsView = styled.div`
  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 120px;
  overflow-x: hidden;
  margin-left: 18%;
`;