import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ReactComponent as UsersIcon } from '@/assets/icons/user.icon.svg';
import { ReactComponent as UsersLightIcon } from '@/assets/icons/user.light.icon.svg';
import { ReactComponent as WifiIcon } from '@/assets/icons/wifi.icon.svg';
import { useEffect, useState } from 'react';
import { Card } from './DashboardCard';
import { numberWithCommas, numberWithCommas2 } from '@/utils/helpers';

const DashboardCards = ({ stats, userCount, transactionLevel }) => {
  return (
    <DashboardCardsView>
      <UsersView>
        <div className="dark">
          <div className="text">
            <h3>{numberWithCommas2(userCount?.totalUsers || '00')}</h3>
            <p>Total Users</p>
          </div>
          <div className="icon">
            <UsersIcon />
          </div>
        </div>

        <div className="light">
          <div className="text">
            <h3>{numberWithCommas2(userCount?.verifiedUsers || '00')}</h3>
            <p>Total Verified Users</p>
          </div>
          <div className="icon">
            <UsersLightIcon />
            <WifiIcon className="wifi" />
          </div>
        </div>
      </UsersView>

      <div className="card-wrapper">
        <Card>
          <h3>₦{numberWithCommas(stats?.totalInvoiceBillValue || '00')}</h3>
          <div>
            <p>Total Invoice/Bill Value</p>
            <p className="grey-text">(Excluding drafts)</p>
          </div>
        </Card>
        <Card>
          <h3>{numberWithCommas2(stats?.totalInvoiceBillVolume || '00')}</h3>
          <div>
            <p>Total Invoices/Bills Volume</p>
            <p className="grey-text">(Excluding drafts)</p>
          </div>
        </Card>
        <Card>
          <h3>₦{numberWithCommas(stats?.totalPaidInvoiceBillValue || '00')}</h3>
          <div>
            <p>Total Paid Invoice/Bill Value</p>
            <p className="grey-text">(Including partial payment)</p>
          </div>
        </Card>
        <Card>
          <h3>{numberWithCommas2(stats?.totalPaidInvoiceBillVolume || '00')}</h3>
          <div>
            <p>Total Paid Invoice/Bills Volume</p>
            <p className="grey-text">(Including partial payment)</p>
          </div>
        </Card>
        <Card>
          <h3>₦{numberWithCommas(stats?.totalBillValue || '00')}</h3>
          <div>
            <p>Total Bills</p>
            <p className="grey-text">(Excluding drafts)</p>
          </div>
        </Card>
        <Card>
          <h3>{numberWithCommas2(stats?.totalBillVolume || '00')}</h3>
          <div>
            <p>Total Bills Volume</p>
            <p className="grey-text">(Excluding drafts)</p>
          </div>
        </Card>
        <Card>
          <h3>₦{numberWithCommas(stats?.totalInvoiceValue || '00')}</h3>
          <div>
            <p>Total Invoice Value</p>
            <p className="grey-text">(Excluding drafts)</p>
          </div>
        </Card>
        <Card>
          <h3>{numberWithCommas2(stats?.totalInvoiceVolume || '00')}</h3>
          <div>
            <p>Total Invoice Volume</p>
            <p className="grey-text">(Excluding drafts)</p>
          </div>
        </Card>
        <Card>
          <h3>₦{numberWithCommas(stats?.totalPaidBillValue || '00')}</h3>
          <div>
            <p>Total Paid Bill Value</p>
            <p className="grey-text">(Including partial payment)</p>
          </div>
        </Card>
        <Card>
          <h3>{numberWithCommas2(stats?.totalPaidBillVolume || '00')}</h3>
          <div>
            <p>Total Paid Bill Volume</p>
            <p className="grey-text">(Including partial payment)</p>
          </div>
        </Card>
        <Card>
          <h3>₦{numberWithCommas(stats?.totalPaidInvoiceValue || '00')}</h3>
          <div>
            <p>Total Paid Invoice Value</p>
            <p className="grey-text">(Including partial payment)</p>
          </div>
        </Card>
        <Card>
          <h3>{numberWithCommas2(stats?.totalPaidInvoiceVolume || '00')}</h3>
          <div>
            <p>Total Paid Invoice Volume</p>
            <p className="grey-text">(Including partial payment)</p>
          </div>
        </Card>
        <Card>
          <h3>₦{numberWithCommas(stats?.totalNonManualInvoiceBillValue || '00')}</h3>
          <div>
            <p>Total Non-manual Invoice/Bill</p>
            <p className="grey-text">(Non-manual payment)</p>
          </div>
        </Card>
        <Card>
          <h3>₦{numberWithCommas(stats?.totalNonManualBillValue || '00')}</h3>
          <div>
            <p>Total Non-manual Bill Value</p>
            <p className="grey-text">(Non-manual payment)</p>
          </div>
        </Card>
        <Card>
          <h3>₦{numberWithCommas(stats?.totalNonManualInvoiceValue || '00')}</h3>
          <div>
            <p>Total Non-manual Invoice Value</p>
            <p className="grey-text">(Non-manual payment)</p>
          </div>
        </Card>
      </div>
      <Divider />

      <TransactionLevel>
        <h3 className="title">TRANSACTIONS LEVEL</h3>
        <div className="card-wrapper">
          <Card>
            <h3>
              ₦{numberWithCommas(transactionLevel?.totalTransactionValueInflowOutflow || '00')}
            </h3>
            <div>
              <p>Total Transaction Value</p>
              <p className="grey-text">(Inflow + Oultflow)</p>
            </div>
          </Card>
          <Card>
            <h3>
              {numberWithCommas2(transactionLevel?.totalTransactionVolumeInflowOutflow || '00')}
            </h3>
            <div>
              <p>Total Transaction Volume</p>
              <p className="grey-text">(Inflow + Oultflow)</p>
            </div>
          </Card>
          <Card>
            <h3>
              ₦
              {numberWithCommas(
                transactionLevel?.totalNonManualTransactionValueInflowOutflow || '00'
              )}
            </h3>
            <div>
              <p>Total Non-manual Transaction Value</p>
              <p className="grey-text">(Inflow + Oultflow)</p>
            </div>
          </Card>
          <Card>
            <h3>
              {numberWithCommas2(
                transactionLevel?.totalNonManualTransactionVolumeInflowOutflow || '00'
              )}
            </h3>
            <div>
              <p>Total Non-manual Transaction Volume</p>
              <p className="grey-text">(Inflow + Oultflow)</p>
            </div>
          </Card>
          <Card>
            <h3>₦{numberWithCommas(transactionLevel?.totalTransactionValueInflow || '00')}</h3>
            <div>
              <p>Total Transaction Value</p>
              <p className="grey-text">(Inflow)</p>
            </div>
          </Card>
          <Card>
            <h3>{transactionLevel?.totalTransactionVolumeInflow}</h3>
            <div>
              <p>Total Transaction Volume</p>
              <p className="grey-text">(Inflow)</p>
            </div>
          </Card>
          <Card>
            <h3>
              ₦{numberWithCommas(transactionLevel?.totalNonManualTransactionValueInflow || '00')}
            </h3>
            <div>
              <p>Total Non-manual Transaction Value</p>
              <p className="grey-text">(Inflow)</p>
            </div>
          </Card>
          <Card>
            <h3>
              {numberWithCommas2(transactionLevel?.totalNonManualTransactionVolumeInflow || '00')}
            </h3>
            <div>
              <p>Total Non-manual Transaction Volume</p>
              <p className="grey-text">(Inflow)</p>
            </div>
          </Card>
          <Card>
            <h3>₦{numberWithCommas(transactionLevel?.totalTransactionValueOutflow || '00')}</h3>
            <div>
              <p>Total Transaction Value</p>
              <p className="grey-text">(Outflow)</p>
            </div>
          </Card>
          <Card>
            <h3>{numberWithCommas2(transactionLevel?.totalTransactionVolumeOutflow || '00')}</h3>
            <div>
              <p>Total Transaction Volume</p>
              <p className="grey-text">(Outflow)</p>
            </div>
          </Card>
          <Card>
            <h3>
              ₦{numberWithCommas(transactionLevel?.totalNonManualTransactionValueOutflow || '00')}
            </h3>
            <div>
              <p>Total Non-manual Transaction Value</p>
              <p className="grey-text">(Outflow)</p>
            </div>
          </Card>
          <Card>
            <h3>
              {numberWithCommas2(transactionLevel?.totalNonManualTransactionVolumeOutflow || '00')}
            </h3>
            <div>
              <p>Total Non-manual Transaction Volume</p>
              <p className="grey-text">(Outflow)</p>
            </div>
          </Card>
        </div>
      </TransactionLevel>
    </DashboardCardsView>
  );
};

export default DashboardCards;

DashboardCards.propTypes = {
  stats: PropTypes.object.isRequired,
  userCount: PropTypes.object.isRequired,
  transactionLevel: PropTypes.object.isRequired
};

const DashboardCardsView = styled.div`
  width: 100%;
  height: 130px;
  border-radius: 6px;

  .card-wrapper {
    width: 100%;
    margin: 2rem 0;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    h3 {
      font-weight: 600;
    }

    .grey-text {
      margin-top: 0.3rem;
      color: ${({ theme }) => theme.colors.grey};
    }
  }
`;

const UsersView = styled.div`
  width: 100%;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.75rem;
  color: ${({ theme }) => theme.colors.white};

  .dark,
  .light {
    width: 50%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    align-items: center;
    padding: 1rem 2rem;

    .text {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      h3 {
        font-size: 24px;
        font-weight: 600;
      }
    }

    .icon {
      width: 70px;
      height: 70px;
      background-color: rgba(255, 255, 255, 0.07);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }
  }

  .dark {
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  .light {
    background-color: ${({ theme }) => theme.colors.primary};

    .wifi {
      position: absolute;
      top: 0;
      right: 6px;
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.line};
`;

const TransactionLevel = styled.div`
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 100%;

  .title {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;
