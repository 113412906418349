import { fetchWallet } from '@/api/walletApi';
import PageLoader from '@/loaders/PageLoader';
import { useQuery } from '@tanstack/react-query';
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as ExtLinkIcon } from '@/assets/icons/ext-link.icon.svg';
import { numberWithCommas, numberWithCommas2 } from "@/utils/helpers";
import { format } from "date-fns";
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { formatInTimeZone } from 'date-fns-tz';

export const WalletInfo = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const wallet = useQuery({
    queryKey: ['wallet', { id }],
    queryFn: fetchWallet,
    onSuccess: (data) => {}
  })

  const details = wallet?.data?.data

  const handleReturn = () => {
    window.history.back();
  };

  if (wallet.isFetching) {
    return <PageLoader />
  }

  return (
    <>
      <WalletsInfoView>
        <div className="return" onClick={handleReturn}>
          <BackIcon />
        </div>
        <DataView>
          <FormData>
            <div className="item">
              <div className="label-view">
                <h3>Wallet Status</h3>
                <Status status={details?.status}>{details?.status}</Status>
              </div>
            </div>

            <div className="item">
              <div className="label-view">
                <h3>Fullname</h3>
                <p className="name">
                  {details?.user?.firstName || '--'} {details?.user?.lastName || '--'}
                </p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Email</h3>
                <p>{details?.user?.email}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Uder Id</h3>
                <p>{details?.user?.id}</p>
              </div>
              <div className="copy-view">
                <button
                  onClick={() => {
                    navigate(`/users/${details?.user?.id}`);
                  }}>
                  View <ExtLinkIcon />
                </button>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Wallet Activation Date</h3>
                <p>{formatInTimeZone(new Date(details?.createdAt), 'UTC', 'do MMM, yyy')}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Last Debit Transaction Date</h3>
                <p>
                  {formatInTimeZone(new Date(details?.lastDebitTransaction), 'UTC', 'do MMM, yyy')}
                </p>
              </div>
            </div>
          </FormData>
          <FormData>
            <div className="item">
              <div className="label-view">
                <h3>Account Name</h3>
                <p className="name">{details?.user?.virtualAccounts[0]?.accountName || '--'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Account Number</h3>
                <p className="name">{details?.user?.virtualAccounts[0]?.accountNumber || '--'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Account Balance</h3>
                <p>₦{numberWithCommas(details?.currentBalance || '00.00')}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Transaction Count</h3>
                <p>
                  {numberWithCommas2(details?.transactionCount || '0')}{' '}
                  {details?.transactionCount === 0 || 1 ? 'transaction' : 'transactions'}
                </p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Amount Credited</h3>
                <p>₦{numberWithCommas(details?.amountCredited || '00.00')}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Amount Debited</h3>
                <p>₦{numberWithCommas(details?.amountDebited || '00.00')}</p>
              </div>
            </div>
          </FormData>
        </DataView>
      </WalletsInfoView>
    </>
  );
}

const Status = styled.p`
  color: ${({ status }) => {
    switch (status) {
      case 'active':
        return '#16A34A';
      case 'pending':
        return '#F4B740';
      case 'processing':
        return '#F4B740';
      case 'initiated':
        return '#F4B740';
      case 'pending':
        return '#F4B740';
      case 'inactive':
        return '#B91C1C';
      case 'declined':
        return '#B91C1C';

      default:
    }
  }};
  text-transform: capitalize;
`;

const WalletsInfoView = styled.div`
  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  padding-top: 120px;
  overflow-x: hidden;
  margin-left: 18%;

  .return {
    width: 30px;
    margin-bottom: 20px;
    cursor: pointer;
  }
`;

const DataView = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const FormData = styled.div`
  height: 100%;
  width: 50%;
  background-color: ${({ theme }) => theme.colors?.white};
  border-radius: 6px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors?.line};

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    .label-view {
      width: 70%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: ${({ theme }) => theme?.colors?.secondary};

      h3 {
        font-size: 0.875rem;
        font-weight: 500;
        color: ${({ theme }) => theme?.colors?.secondary};
        margin-bottom: 8px;
      }

      p {
        font-size: 0.875rem;
        font-weight: 600;
      }

      .name {
        text-transform: capitalize;
      }
    }

    .copy-view {
      width: 120px;
      display: flex;
      align-items: center;

      button,
      a {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: ${({ theme }) => theme?.colors?.info};
        font-size: 0.875rem;

        svg {
          margin-left: 5px;
          width: 16px;
          height: 16px;

          path {
            stroke: ${({ theme }) => theme?.colors?.info};
          }
        }
      }
    }
  }

  .last {
    border-bottom: none;
    padding-bottom: 0;
  }
`;