import { fetchUserActivationTime, fetchUsersStats } from "@/api/userApi";
import { Card } from "@/components/MetricsCard";
import TableLoader from "@/loaders/TableLoader";
import { numberWithCommas2 } from "@/utils/helpers";
import { useQuery } from "@tanstack/react-query";
import { Oval } from "react-loader-spinner";
import styled from "styled-components";
import { useEffect, useRef, useState } from 'react';
import useOnClickOutside from '@/hooks/useClickOutside';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as FilterIcon } from '@/assets/icons/filter.icon.svg';
import toast from "react-hot-toast";

const UsersStats = () => {
  const filterOptions = [
    { value: 'allTime', label: 'All Time' },
    { value: 'currentWeek', label: 'Current Week' },
    { value: 'lastWeek', label: 'Last Week' },
    { value: 'currentMonth', label: 'Current Month' },
    { value: 'lastMonth', label: 'Last Month' },
    { value: 'last3Month', label: 'Last Three Months' },
    { value: 'currentYear', label: 'Current Year' },
    { value: 'lastYear', label: 'Last Year' }
  ];

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startDateValue, setStartDateValue] = useState('');
  const [endDateValue, setEndDateValue] = useState('');
  const [fetch, setFetch] = useState('currentMonth');
  const [fetchValue, setFetchValue] = useState(
    filterOptions.find((option) => option.value === 'currentMonth')
  );
  const [showMenu, setShowMenu] = useState(false);
  const [showDateMenu, setShowDateMenu] = useState(false);
  const [showFilterMenu, setShowFilterMenu] = useState(false);

  const menuRef = useRef();
  useOnClickOutside(menuRef, () => setShowMenu(false));

  const dateRef = useRef();
  useOnClickOutside(dateRef, () => setShowDateMenu(false));

  const filterRef = useRef();
  useOnClickOutside(filterRef, () => setShowFilterMenu(false));

  const stats = useQuery({
    queryKey: ['stats', { fetch, startDate, endDate }],
    queryFn: fetchUsersStats
  });

  const times = useQuery({
    queryKey: ['times', { fetch, startDate, endDate }],
    queryFn: fetchUserActivationTime
  });

  const handleFilterSubmit = () => {
    setStartDate('');
    setEndDate('');
  
    // Update the `fetch` state with the selected value
    setFetch(fetchValue.value);
    setShowFilterMenu(false);
    // Clear the selected fetch value after submission
    setFetchValue('');
  };

  const handleDateFilter = () => {
    //Convert startDateValue and endDateValue to objects
    const start = new Date(startDateValue);
    const end = new Date(endDateValue);

    //Calculate the difference in months
    const differenceInMonths =
      (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());

    console.log(differenceInMonths);

    // Show a toast notification if the difference is 3 months or more
    if (differenceInMonths >= 3) {
      toast.success('An email will be sent to you shortly');
    }

    setStartDate(startDateValue);
    setEndDate(endDateValue);
    setEndDateValue('');
    setStartDateValue('');
    setShowDateMenu(false);
  };

  useEffect(() => {
    const currentOption = filterOptions.find((option) => option.value === fetch);
    if (currentOption) {
      setFetchValue(currentOption);
    }
  }, [fetch]);

  const stat = stats?.data?.data;
  const time = times?.data?.data;

  const percentageActivationRate = () => {
    const rate = time?.activation_time?.activation_rate || 0;
    const users = stat?.total_users || 0;
    const activationRate = (rate / users) * 100;

    return activationRate;
  };

  if (stats.isFetching) {
    return (
      <LoaderView>
        <div className="loading-view">
          <Oval color="#92C22C" width={50} height={50} strokeWidth={5} />
        </div>
      </LoaderView>
    );
  }

  return (
    <>
      <div>
        <Title>Sign Ups</Title>
        <UsersStatsView>
          <div className="card-wrapper">
            <Card>
              <h3>{numberWithCommas2(stat?.daily_signup || '0')}</h3>
              <p>Signups Today</p>
            </Card>
            <Card>
              <h3>{numberWithCommas2(stat?.weekly_signup || '0')}</h3>
              <p>Signups This Week</p>
            </Card>
            <Card>
              <h3>{numberWithCommas2(stat?.monthly_signup || '0')}</h3>
              <p>Signups This Month</p>
            </Card>
            <Card>
              <h3>{numberWithCommas2(stat?.total_signup || '0')}</h3>
              <p>Total Signups</p>
            </Card>
          </div>
        </UsersStatsView>
      </div>

      <div style={{ marginTop: '2rem' }}>
        <Filter className="filter">
          <Title>{!startDate && !endDate ? fetchValue.label : `${startDate} to ${endDate}`}</Title>

          <button className="filter-btn" onClick={() => setShowMenu(true)}>
            <FilterIcon /> Filter Metrics
          </button>

          {/* ===== Menu Options ====== */}
          <AnimatePresence>
            {showMenu ? (
              <motion.div
                className="menu-dropdown"
                ref={menuRef}
                initial={{ y: -20 }}
                animate={{ y: 0 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}>
                <button
                  className="select"
                  onClick={() => {
                    setShowMenu(false);
                    setShowFilterMenu(true);
                  }}>
                  Filter by Select
                </button>
                <button
                  className="select"
                  onClick={() => {
                    setShowMenu(false);
                    setShowDateMenu(true);
                  }}>
                  Filter by Date
                </button>
              </motion.div>
            ) : null}
          </AnimatePresence>

          {/* ===== Date Menu ====== */}
          <AnimatePresence>
            {showDateMenu ? (
              <motion.div
                className="export-menu"
                ref={dateRef}
                initial={{ y: -20 }}
                animate={{ y: 0 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}>
                <div className="heading">
                  <span>Filter Metrics</span>
                </div>
                <div className="body">
                  <div className="item">
                    <div className="date-input">
                      <label>From:</label>
                      <input
                        type="date"
                        name="startDate"
                        // value={startDate}
                        // onChange={(e) => setStartDate(e.target.value)}
                        // placeholder="Select Start Date"
                        value={startDateValue}
                        onChange={(e) => setStartDateValue(e.target.value)}
                      />
                    </div>
                    <div className="date-input">
                      <label>To:</label>
                      <input
                        type="date"
                        name="endDate"
                        // value={endDate}
                        // onChange={(e) => setEndDate(e.target.value)}
                        // placeholder="Select End Date"
                        value={endDateValue}
                        onChange={(e) => setEndDateValue(e.target.value)}
                      />
                    </div>
                  </div>
                  <button className="done" onClick={handleDateFilter}>
                    Filter
                  </button>
                </div>
              </motion.div>
            ) : null}
          </AnimatePresence>

          {/* ===== Filter Menu ====== */}
          <AnimatePresence>
            {showFilterMenu ? (
              <motion.div
                className="filter-menu"
                ref={filterRef}
                initial={{ y: -20 }}
                animate={{ y: 0 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}>
                <div className="heading">
                  <span>Filters</span>
                  <button
                    className="done"
                    disabled={!fetchValue}
                    onClick={() => {
                      handleFilterSubmit();
                    }}>
                    Done
                  </button>
                </div>
                <div className="body">
                  <div className="item">
                    <h3>Duration</h3>
                    {filterOptions.map((option) => {
                      return (
                        <div className="input" key={option.value}>
                          <input
                            type="radio"
                            name="duration"
                            value={option.value}
                            checked={fetchValue.value === option.value}
                            onChange={(e) => {
                              setFetchValue(option);
                              // setFetchValue(e.target.value);
                            }}
                          />
                          <label>{option.label}</label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </motion.div>
            ) : null}
          </AnimatePresence>
        </Filter>
        <UsersStatsView>
          <div className="card-wrapper">
            <Card>
              <h3>{numberWithCommas2(stat?.non_verified_user || '0')}</h3>
              <p>Non Verified User</p>
            </Card>
            <Card>
              <h3>{numberWithCommas2(stat?.onboarding || '0')}</h3>
              <p>Onboarding</p>
            </Card>
            <Card>
              <h3>{numberWithCommas2(stat?.active_users || '0')}</h3>
              <p>Active Users</p>
            </Card>
            <Card>
              <h3>{numberWithCommas2(stat?.total_users || '0')}</h3>
              <p>Total Users</p>
            </Card>
          </div>
        </UsersStatsView>
        <UsersStatsView>
          <div className="card-wrapper">
            <Card>
              <h3>{numberWithCommas2(stat?.activation || '0')}</h3>
              <div>
                <p>Activation</p>
                <p className="grey-text">(Compliant users)</p>
              </div>
            </Card>
            <Card>
              <h3>{numberWithCommas2(stat?.repeat_user || '0')}</h3>
              <div>
                <p>Repeat Users</p>
                <p className="grey-text">(Returning customers)</p>
              </div>
            </Card>
            <Card>
              <h3>{numberWithCommas2(percentageActivationRate())}%</h3>
              <div>
                <p>Activation Rate</p>
                <p className="grey-text">(% active new users)</p>
              </div>
            </Card>
            <Card>
              <h3>{numberWithCommas2(time?.activation_time?.activation_time || '0')} Day(s)</h3>
              <div>
                <p>Activation Time</p>
                <p className="grey-text">(Time to activity)</p>
              </div>
            </Card>
          </div>
        </UsersStatsView>
      </div>

      {/* <UsersStatsView>
        <div className="card-wrapper">
        </div>
      </UsersStatsView> */}
    </>
  );
}

export default UsersStats

const Filter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .menu-dropdown {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 35px;
    right: 0px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow:
      0px 0px 2px rgba(125, 125, 125, 0.1),
      0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 10;
    max-width: 200px;
    border: none;
    padding: 0px 0px;

    .select {
      width: 100%;
      border-radius: 10px;
      padding: 1.2rem 2rem;
      margin-right: 0;

      &:hover {
        background-color: ${({ theme }) => theme.colors.inputBackground};
      }
    }
  }

  .filter-menu {
    position: absolute;
    top: 35px;
    right: 0px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow:
      0px 0px 2px rgba(125, 125, 125, 0.1),
      0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 10;
    width: 300px;
    border: none;
    padding: 20px 12px;

    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      span {
        font-size: 1.1rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.black};
      }

      .done {
        background-color: transparent;
        color: ${({ theme }) => theme.colors?.secondary};
        border: none;
        outline: none;
        font-size: 0.875rem;
        font-weight: 600;
        cursor: pointer;
        margin: 0;
        padding: 0;
        width: auto;
      }
    }

    .body {
      .item {
        width: 100%;
        margin-bottom: 20px;

        h3 {
          font-size: 1rem;
          font-weight: 500;
          color: ${({ theme }) => theme.colors?.black};
          margin-bottom: 10px;
        }

        .input {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          input {
            margin-right: 10px;
            accent-color: ${({ theme }) => theme.colors?.secondary};
            cursor: pointer;
          }
        }

        select {
          width: 100%;
          height: 40px;
          outline: none;
          border: 1px solid ${({ theme }) => theme.colors.line};
          border-radius: 6px;
          padding: 0 10px;
          font-size: 0.875rem;
          font-weight: 400;
          color: ${({ theme }) => theme.colors?.placeholder};
          cursor: pointer;
        }
      }
    }
  }

  .export-menu {
    position: absolute;
    top: 35px;
    right: 0px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow:
      0px 0px 2px rgba(125, 125, 125, 0.1),
      0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 10;
    width: 300px;
    border: none;
    padding: 20px 12px;

    .body {
      margin-top: 20px;

      .item {
        width: 100%;
        margin-bottom: 20px;

        .date-input {
          gap: 0.4rem;
          display: flex;
          align-items: start;
          margin-bottom: 10px;
          flex-direction: column;
          justify-content: start;

          input {
            width: 100%;
            border: none;
            cursor: pointer;
            margin-right: 10px;
            border-radius: 6px;
            padding: 0.5rem 0.65rem;
            color: ${({ theme }) => theme.colors?.black};
            background-color: ${({ theme }) => theme.colors?.inputBackground};
          }
        }
      }

      .done {
        margin: 0;
        width: auto;
        border: none;
        outline: none;
        cursor: pointer;
        font-weight: 600;
        border-radius: 6px;
        padding: 0.6rem 2rem;
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.white};
        background-color: ${({ theme }) => theme.colors?.secondary};
      }
    }
  }

  button {
    width: 120px;
    height: 100%;
    border: none;
    outline: none;
    display: flex;
    margin-right: 1rem;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 10px;
    }
  }

  .sub-update {
    width: 200px;
    background-color: ${({ theme }) => theme.colors?.secondary};
    border-radius: 6px;
    color: ${({ theme }) => theme.colors?.white};
    font-weight: 600;
    transition: all 0.2s ease-in-out;
  }
`;

const Title = styled.h3`
  font-size: 1.2rem;
  font-weight: 600;
  
  color: ${({ theme }) => theme.colors.secondary};
`;

const LoaderView = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .loading-view {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const UsersStatsView = styled.div`
  width: 100%;
  height: 130px;
  margin-bottom: 20px;
  margin-top: 20px;
  border-radius: 6px;
  display: flex;
  align-items: center;

  .card-wrapper {
    width: 100%;
    display: flex;
    gap: 1rem;

    &:nth-child(even) {
      background-color: ${({ theme }) => theme.colors.alternateBackground2};
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
    }

    .grey-text {
      margin-top: 0.3rem;
      color: ${({ theme }) => theme.colors.grey};
    }
  }
`;

const MetricView = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  h3 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.secondary};
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 80%;
  background-color: ${({ theme }) => theme.colors.line};
`;
