import { styled } from 'styled-components';
import { useForm } from 'react-hook-form';
import { Oval } from 'react-loader-spinner';
import toast from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { ReactComponent as Logo } from '@/assets/icons/logo.svg';
import { ReactComponent as EyeIcon } from '@/assets/icons/eye.icon.svg';
import { ReactComponent as EyeOffIcon } from '@/assets/icons/eye-off.icon.svg';
import Input from '@/components/Input';
import Button from '@/components/Button';
import { setUser } from '@/redux/features/appSlice';
import { fetchProfile, loginUser } from '@/api/authApi';
import useAuthentication from '@/hooks/useAuthentication';
import secureLocalStorage from 'react-secure-storage';
import { useState } from 'react';

const emailRegEx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const schema = yup
  .object({
    email: yup
      .string()
      .required('Email is required')
      .matches(emailRegEx, 'Must be a valid email')
      .max(255),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password should not be less than 8 characters')
  })
  .required();

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { authenticated } = useAuthentication();
  const [showPassword, setShowPassword] = useState(false)

  const handleLogin = useMutation(loginUser, {
    onSuccess: async (data) => {
      queryClient.invalidateQueries({ queryKey: ['user'] });
      secureLocalStorage.setItem('ut', data?.data?.accessToken);
      secureLocalStorage.setItem('rt', null);
      secureLocalStorage.setItem('auth', true);

      const user = await queryClient.fetchQuery({
        queryKey: ['user'],
        queryFn: fetchProfile
      });

      if (user) {
        toast.success('Login successful');
        dispatch(setUser(user?.data?.data));

        console.log(user?.data);

        navigate('/dashboard', { replace: true });
      }
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data) => {
    handleLogin.mutate(data);
  };

  if (authenticated) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <LoginView>
      <LogoView>
        <Logo />
      </LogoView>
      <TextView>
        <h1>Login</h1>
        <p>Login to continue as Admin</p>
      </TextView>

      <FormView onSubmit={handleSubmit(onSubmit)}>
        <InputView>
          <LabelView>
            <label htmlFor="email">Email</label>
          </LabelView>
          <Input
            type="email"
            placeholder="Enter company email."
            {...register('email')}
            error={!!errors?.email?.message}
          />
          {errors.email && <span className="error-label">{errors.email.message}</span>}
        </InputView>

        <InputView>
          <LabelView>
            <label htmlFor="email">Password</label>
          </LabelView>
          <div className="input">
            <Input
              type={showPassword ? 'text' : 'password'}
              placeholder="Enter password."
              width="100%"
              height="64px"
              {...register('password')}
              error={!!errors?.password?.message}
            />
            <button type='button' onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <EyeOffIcon/> : <EyeIcon/>}
            </button>
          </div>
          {errors.password && <span className="error-label">{errors.password.message}</span>}
        </InputView>

        <ForgotView>
          <p>
            Forgot password? <Link to="/forgot-password">Reset</Link>
          </p>
        </ForgotView>

        <ButtonView>
          <Button type="submit" styletype="primary">
            {handleLogin.isLoading ? (
              <Oval
                color="#FFF"
                height={30}
                width={30}
                ariaLabel="loading"
                strokeWidth={5}
                secondaryColor="#eee"
              />
            ) : (
              'Log In'
            )}
          </Button>
        </ButtonView>
      </FormView>
    </LoginView>
  );
}

export default Login;

const LoginView = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors?.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TextView = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 8px;
    color: ${({ theme }) => theme.colors?.secondary};
  }

  p {
    color: ${({ theme }) => theme.colors?.secondary};
  }
`;

const LogoView = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;

const FormView = styled.form`
  width: 32%;
  margin-top: 50px;
`;

const InputView = styled.div`
  width: 100%;
  margin-bottom: 26px;

  .input {
    position: relative;

    button {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 5px;
    }
  }

  .error-label {
    font-size: 0.8rem;
    color: ${({ theme }) => theme.colors.error_200};
    margin-top: 5px;
  }
`;

const LabelView = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 0px;

  label {
    font-size: 1rem;
  }
`;

const ForgotView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 19px;

    a {
      color: ${({ theme }) => theme.colors?.secondary};
      font-weight: 500;
    }
  }
`;

const ButtonView = styled.div`
  width: 100%;
  margin: 50px 0 40px 0;
`;
