/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import SubscriptionList from './SubscriptionList';
import ActiveSubscribers from './ActiveSubscribers';
const tabs = [
  {
    name: 'Subscription Plans',
    path: 'plans',
    value: 'plans'
  },
  {
    name: 'Active Paid Subscribers',
    path: 'subscribers',
    value: 'subscribers'
  }
];

function Tabs() {
  const [searchParams, setSearchParams] = useSearchParams();
  let tab = searchParams.get('tab');
  const navigate = useNavigate();

  useEffect(() => {
    // Check if 'tab' parameter is not present in the URL
    if (!searchParams.get('tab')) {
      // Set the default URL parameters for the "Subscription Plans" tab
      setSearchParams('tab=plans');
      // Optionally, you can also navigate to the new URL
      navigate('?tab=plans');
    }
  }, [searchParams, navigate, setSearchParams]);

  return (
    <>
      <StyledTabs>
        <ContentView>
          <TabsView>
            {tabs.map((tabItem) => (
              <div key={tabItem.path} className="tab-item">
                <button
                  type="button"
                  className={`tab-btn ${tab === tabItem.value ? 'active' : ''}`}
                  onClick={() => setSearchParams(`tab=${tabItem?.path}`)}>
                  {tabItem.name}
                </button>
              </div>
            ))}
          </TabsView>
          <InfoView>
            {tab === 'plans' && <SubscriptionList />}
            {tab === 'subscribers' && <ActiveSubscribers />}
          </InfoView>
        </ContentView>
      </StyledTabs>
    </>
  );
}

export default Tabs;

export const StyledTabs = styled.div`
  width: 100%;
  height: 100%;
`;

export const ContentView = styled.div`
  width: 100%;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
`;

export const TabsView = styled.div`
  width: auto;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
  overflow-x: scroll;

  .tab-item {
    margin-right: 10px;
    display: flex;

    button {
      width: fit-content;
      height: 44px;
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.body};
      transition: all 0.3s ease-in-out;
      padding: 12px 32px;
      border-radius: 8px;
      word-break: normal;
      white-space: nowrap;

      &:hover {
        color: ${({ theme }) => theme.colors?.secondary};
        background-color: ${({ theme }) => theme.colors?.line};
      }
    }

    .active {
      color: ${({ theme }) => theme.colors?.secondary};
      background-color: ${({ theme }) => theme.colors?.line};
    }
  }
`;

export const InfoView = styled.div`
  width: 100%;
  height: 100%;
`;
