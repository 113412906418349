import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import CreditWalletsHistory from './CreditWalletsHistory';
import DebitWalletsHistory from './DebitWalletsHistory';
import AllWalletsHistory from './AllWalletsHistory';

export const WalletsHistory = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  let view = searchParams.get('view');
  let tab = searchParams.get('tab');
  // let tab = searchParams.get('view');
  const navigate = useNavigate();

  useEffect(() => {
    // Check if 'tab' parameter is not present in the URL
    if (!searchParams.get('tab')) {
      // Set the default URL parameters for the "Subscription Plans" tab
      setSearchParams({ tab: 'history', view: 'all-history' });
      // Optionally, you can also navigate to the new URL
      navigate('?tab=history&view=all-history');
    }
  }, [searchParams, navigate, setSearchParams]);

  const tabs = [
    {
      name: "All Transactions",
      path: "view=all-history",
      value: "all-history",
    },
    {
      name: "Credit Transactions",
      path: "view=credit-history",
      value: "credit-history",
    },
    {
      name: "Debit Transactions",
      path: "view=debit-history",
      value: "debit-history",
    }
  ];

  return (
    <>
      <WalletHistoryView>
        <ContentView>
          <TabView>
            {tabs.map((tabItem) => (
              <button
                key={tabItem.path}
                className={`tabs ${view === tabItem.value ? 'active' : ''}`}
                onClick={() => setSearchParams({tab: 'history', view: tabItem.value })}
              >
                {tabItem.name}
              </button>
            ))}
          </TabView>
          <TableView>
            {view === 'all-history' && <AllWalletsHistory />}
            {view === 'credit-history' && <CreditWalletsHistory/>}
            {view === 'debit-history' && <DebitWalletsHistory />}
          </TableView>
        </ContentView>
      </WalletHistoryView>
    </>
  )
}

const WalletHistoryView = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 50px;
`;

const TableView = styled.div`
  width: 100%;
  height: 100%;
`;

const ContentView = styled.div`
  width: 100%;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
`;

const Divider = styled.div`
  width: 1px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.line};
`;

const TabView = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  position: fixed;
  z-index: 1000000;
  margin-left: -30px;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors?.white};
  border-bottom: 1px solid ${(props) => props.theme?.colors?.line};

  button {
    padding: 16px 32px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    border-bottom: 2px solid transparent;

    &:hover {
    color: ${(props) => props.theme?.colors?.secondary};
    }
  }
  
  .active {
    color: ${(props) => props.theme?.colors?.secondary};
    border-bottom: 2px solid ${(props) => props.theme?.colors?.secondary};
  }
`;