import PageHeader from '@/components/PageHeader';
import withAuthentication from '@/hooks/withAuthentication';
import styled from 'styled-components';
import SubscriptionList from './components/SubscriptionList';
import SubscriptionMetrics from './components/SubscriptionMetrics';
import Tabs from './components/Tab';
import { usePermissions } from '@/hooks/usePermissions';
import { EmptyState } from '@/components/EmptyState';

function Subscriptions() {
  const { permissions, hasPermission, superAdmin } = usePermissions();
  const isASuperAdmin = superAdmin(true);
  const tabs = [
    {
      name: 'Subscription Plans',
      path: 'tab=subscriptions&view=plans&state=subscription-plans',
      value: 'subscription plans'
    },
    {
      name: 'Active Paid Subscribers',
      path: 'tab=subscriptions&view=plans&state=annual',
      value: 'annual'
    }
  ];

  const canReadSubscriptions = hasPermission('subscription', 'read');

  return (
    <>
      <PageHeader title="Subscriptions" subtitle="View and manage subscriptions" />
      <SubscriptionsView>
        {canReadSubscriptions || isASuperAdmin ? (
          <div>
            <SubscriptionMetrics />
            <Tabs />
          </div>
        ) : (
          <EmptyState />
        )}
        {/* <div>
          <SubscriptionMetrics />
          <Tabs />
        </div> */}

        {/* <SubscriptionList /> */}
      </SubscriptionsView>
    </>
  );
}

export default withAuthentication(Subscriptions);

const SubscriptionsView = styled.div`
  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 120px;
  overflow-x: hidden;
  margin-left: 18%;
`;
