import { fetchAccount, getModules, getRoles, updateAccount } from '@/api/accountsApi';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as CloseIcon } from '@/assets/icons/cancel.icon.svg';
import { ReactComponent as ArrowDownIcon } from '@/assets/icons/nav-down.svg';
import useOnClickOutside from '@/hooks/useClickOutside';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@/components/Button';
import Input from '@/components/Input';
import { toast } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import styled from 'styled-components';
import Select from 'react-select';

const emailRegEx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const schema = yup
  .object({
    email: yup
      .string()
      .required('Email is required')
      .matches(emailRegEx, 'Must be a valid email')
      .max(255),
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required')
  })
  .required();

export const UpdateAccountModal = ({ showModal, setShowModal, selectedId }) => {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [permissions, setPermissions] = useState([]);

  const ref = useRef();
  useOnClickOutside(ref, () => setOpen(false));

  const roles = useQuery({
    queryKey: ['roles'],
    queryFn: getRoles
  });

  const modules = useQuery({
    queryKey: ['modules'],
    queryFn: getModules
  });

  const adminData = roles?.data?.data?.roles;

  const module = modules?.data?.data?.modules;

  const account = useQuery({
    queryKey: ['account', { id: selectedId }],
    queryFn: fetchAccount,
    onSuccess: (data) => {
      setSelectedRole({ name: data.data.role, id: data.data.roleId });
      setPermissions(data?.data?.permissions);
      reset({
        firstName: data?.data?.firstName,
        lastName: data?.data?.lastName,
        email: data?.data?.email,
        password: account?.data?.data?.password
      });
    }
  });

  const handleUpdateAccount = useMutation({
    mutationFn: (data) => updateAccount(data),
    onSuccess: () => {
      setShowModal(false);
      toast.success('Account updated successfully');
      queryClient.invalidateQueries(['accounts']);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: account?.data?.data?.firstName,
      lastName: account?.data?.data?.lastName,
      email: account?.data?.data?.email,
      password: account?.data?.data?.password
    }
  });

  const handleRoleChange = (event) => {
    const selectedValue = event.target.value;
    const selectedRoleObj = adminData.find((role) => role.name === selectedValue);
    setSelectedRole(selectedRoleObj);
  };

  // const handlePermissionChange = (moduleId, permission, permissionId, value) => {
  //   const updatedPermissions = permissions.map((p) => {
  //     if (p.modulesId === moduleId && p.id === permissionId) {
  //       console.log(p);
  //       return {
  //         ...p,
  //         [permission]: value
  //       };
  //     }
  //     return p;
  //   });
  //   setPermissions(updatedPermissions);
  //   console.log(updatedPermissions);
  // };

  // const handleNewPermissionChange = (moduleId, permission, value) => {
  //   setPermissions((prevPermissions) => {
  //     // Check if the moduleId already exists in the permissions array
  //     const existingPermissionIndex = prevPermissions.findIndex((p) => p.modulesId === moduleId);

  //     if (existingPermissionIndex !== -1) {
  //       // If moduleId exists, update the corresponding permission
  //       const updatedPermissions = prevPermissions.map((p, index) => {
  //         if (index === existingPermissionIndex) {
  //           return {
  //             ...p,
  //             [permission]: value
  //           };
  //         }
  //         return p;
  //       });
  //       console.log(updatedPermissions);
  //       return updatedPermissions;
  //     } else {
  //       // If moduleId does not exist, add a new permission object
  //       const newPermission = {
  //         moduleId: moduleId,
  //         create: permission === 'create' ? value : false,
  //         read: permission === 'read' ? value : false,
  //         update: permission === 'update' ? value : false,
  //         delete: permission === 'delete' ? value : false
  //       };
  //       const newPermissions = [...prevPermissions, newPermission];

  //       // Log the updated permissions here
  //       console.log(newPermissions);
  //       return newPermissions;

  //       // return [...prevPermissions, newPermission];
  //     }
  //   });
  // };

  const handlePermissionChange = (moduleId, permission, permissionId = null, value) => {
    setPermissions((prevPermissions) => {
      // Check if the moduleId already exists in the permissions array
      const existingPermissionIndex = prevPermissions.findIndex(
        (p) => p.modulesId === moduleId && (!permissionId || p.id === permissionId)
      );

      if (existingPermissionIndex !== -1) {
        // If the permissionId or moduleId exists, update the corresponding permission
        const updatedPermissions = prevPermissions.map((p, index) => {
          if (index === existingPermissionIndex) {
            return {
              ...p,
              [permission]: value
            };
          }
          return p;
        });
        console.log(updatedPermissions);
        return updatedPermissions;
      } else {
        // If moduleId does not exist, add a new permission object
        const newPermission = {
          modulesId: moduleId,
          create: permission === 'create' ? value : false,
          read: permission === 'read' ? value : false,
          update: permission === 'update' ? value : false,
          delete: permission === 'delete' ? value : false
        };
        const newPermissions = [...prevPermissions, newPermission];
        console.log(newPermissions);
        return newPermissions;
      }
    });
  };


  const onSubmit = (data) => {
    const formData = {
      ...data,
      role: selectedRole?.name,
      roleId: selectedRole?.id,
      permissions: permissions
    };

    const formData2 = {
      ...data,
      role: selectedRole?.name,
      roleId: selectedRole?.id,
      permissions: module.map((mod) => {
        const permissionObj = permissions.find((p) => p.moduleId === mod.id) || {};
        return {
          moduleId: mod.id,
          create: permissionObj.create || false,
          read: permissionObj.read || false,
          update: permissionObj.update || false,
          delete: permissionObj.delete || false
        };
      })
    };

    if (permissions.length === 0) {
      console.log(formData2);
      handleUpdateAccount.mutate(formData2);
    } else {
      console.log(formData);
      handleUpdateAccount.mutate(formData);
    }
  };

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======== Content ======== */}
            <ModalContent>
              <ContentView>
                <HeaderView>
                  <h3>Edit admin account</h3>
                  <button
                    className="close"
                    onClick={() => {
                      setShowModal(false);
                    }}>
                    <CloseIcon />
                  </button>
                </HeaderView>

                <Content>
                  <FormView onSubmit={handleSubmit(onSubmit)}>
                    <InputView>
                      <LabelView>
                        <label htmlFor="email">First name</label>
                      </LabelView>
                      <Input
                        type="text"
                        placeholder="Enter first name."
                        {...register('firstName')}
                        error={!!errors?.firstName?.message}
                      />
                      {errors.firstName && (
                        <span className="error-label">{errors.firstName.message}</span>
                      )}
                    </InputView>

                    <InputView>
                      <LabelView>
                        <label htmlFor="email">Last name</label>
                      </LabelView>
                      <Input
                        type="text"
                        placeholder="Enter last name."
                        {...register('lastName')}
                        error={!!errors?.lastName?.message}
                      />
                      {errors.lastName && (
                        <span className="error-label">{errors.lastName.message}</span>
                      )}
                    </InputView>

                    <InputView>
                      <LabelView>
                        <label htmlFor="email">Email</label>
                      </LabelView>
                      <Input
                        type="email"
                        placeholder="Enter company email."
                        {...register('email')}
                        error={!!errors?.email?.message}
                      />
                      {errors.email && <span className="error-label">{errors.email.message}</span>}
                    </InputView>

                    <InputView>
                      <LabelView>
                        <label htmlFor="email">Role</label>
                      </LabelView>

                      <select
                        className="input-select"
                        value={selectedRole ? selectedRole.name : ''}
                        onChange={handleRoleChange}>
                        <option value="" disabled>
                          Select a role
                        </option>
                        {adminData?.map((role) => (
                          <option key={role.id} value={role.name}>
                            {role.name}
                          </option>
                        ))}
                      </select>

                      {/* <button className="icon" type="button">
                        <ArrowDownIcon />
                      </button> */}
                    </InputView>

                    {/* <InputView>
                      <LabelView>
                        <label htmlFor="email">Password</label>
                      </LabelView>
                      <Input
                        type="text"
                        placeholder="Enter password."
                        width="100%"
                        height="64px"
                        {...register('password')}
                        error={!!errors?.password?.message}
                      />
                      {errors.password && (
                        <span className="error-label">{errors.password.message}</span>
                      )}
                    </InputView> */}

                    {selectedRole ? (
                      <InputView>
                        <LabelView>
                          <label htmlFor="permissions">Permissions</label>
                        </LabelView>

                        <div className="permissions-container">
                          {module?.map((mod, index) => {
                            return (
                              <div key={index} className="module-item">
                                <span>{mod.name}:</span>
                                <div className="permissions">
                                  {['create', 'read', 'update', 'delete']?.map((permission) => {
                                    const permissionObj = permissions.find(
                                      (p) => p.modulesId === mod.id
                                    );
                                    return (
                                      <label key={permission} className="permission-label">
                                        <input
                                          type="checkbox"
                                          // defaultChecked={
                                          //   permissions[index] && permissions[index][permission]
                                          // }
                                          // checked={permissions[index] && permissions[index][permission]}
                                          checked={permissionObj && permissionObj[permission]}
                                          // defaultChecked={
                                          //   permissions.find((p) => p.modulesId === mod.id)?.[permission] || false
                                          // }
                                          // onChange={(e) => {
                                          //   const permissionId = permissionObj
                                          //     ? permissionObj.id
                                          //     : null;
                                          //   if (permissions.length === 0 || !permissionObj) {
                                          //     console.log(mod.id, permission, e.target.checked),
                                          //       handleNewPermissionChange(
                                          //         mod.id,
                                          //         permission,
                                          //         e.target.checked
                                          //       );
                                          //   } else {
                                          //     console.log(
                                          //       mod.id,
                                          //       permission,
                                          //       permissionId,
                                          //       e.target.checked
                                          //     ),
                                          //       handlePermissionChange(
                                          //         mod.id,
                                          //         permission,
                                          //         permissionId,
                                          //         e.target.checked
                                          //       );
                                          //   }
                                          // }}
                                          onChange={(e) => {
                                            const permissionId = permissionObj
                                              ? permissionObj.id
                                              : null;

                                            // Use the unified function for both new and existing permissions
                                            console.log(
                                              mod.id,
                                              permission,
                                              permissionId,
                                              e.target.checked
                                            );

                                            handlePermissionChange(
                                              mod.id, // moduleId
                                              permission, // 'create', 'read', 'update', or 'delete'
                                              permissionId, // either the existing permission ID or null for new
                                              e.target.checked // the new value (true or false)
                                            );
                                          }}
                                        />
                                        {permission.charAt(0).toUpperCase() + permission.slice(1)}
                                      </label>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </InputView>
                    ) : null}

                    <ButtonView>
                      <Button type="submit" styletype="primary">
                        {handleUpdateAccount.isLoading ? (
                          <Oval
                            color="#FFF"
                            height={30}
                            width={30}
                            ariaLabel="loading"
                            strokeWidth={5}
                            secondaryColor="#eee"
                          />
                        ) : (
                          'Update account'
                        )}
                      </Button>
                    </ButtonView>
                  </FormView>
                </Content>
              </ContentView>
            </ModalContent>
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
};

const ModalView = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors?.white};
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 101;
    height: 100%;
    width: 1300px;
    margin: 0 auto;
    padding-top: 50px;
    margin-bottom: 50px;
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    h3 {
      font-size: 1rem;
      font-weight: 600;
    }
  }
`;

const ContentView = styled.div`
  width: 100%;
`;

const HeaderView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-size: 1.2rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors?.secondary};
  }

  .close {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: #f3f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Content = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FormView = styled.form`
  width: 500px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const InputView = styled.div`
  width: 100%;
  margin-bottom: 26px;

  .error-label {
    font-size: 0.8rem;
    color: ${({ theme }) => theme.colors.error_200};
    margin-top: 5px;
  }

  .permissions-container {
    .module-item {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      span {
        font-weight: 500;
      }

      .permissions {
        display: flex;
        gap: 10px;
        margin-top: 5px;

        .permission-label {
          display: flex;
          font-size: 12px;
          align-items: center;
          margin-right: 10px;
          /* padding-left: 5px; */

          /* &input {
            margin-right: 20px;
          } */

          input[type='checkbox'] {
            /* appearance: none;
            -webkit-appearance: none; */
            display: flex;
            align-content: center;
            justify-content: center;
            /* font-size: 2rem; */
            /* padding: 0.1rem; */
            margin-right: 5px;
            accent-color: ${({ theme }) => theme.colors?.secondary};
            border: 1px solid ${({ theme }) => theme.colors?.secondary};
            border-radius: 50%;
          }
        }
      }
    }
  }

  select {
    padding: 0 1rem;
    margin-top: 5px;
    width: 100%;
    height: 4rem;
    border-radius: 5px;
    border: none;
    outline: none;
    /* -webkit-appearance: none;
    -moz-appearance: none; */
    /* appearance: none; */
    padding: 0 20px;
    background-color: ${({ theme }) => theme.colors?.inputBackground};
  }

  .input-select {
    height: 4rem;
    width: 100%;
    position: relative;
  }

  .select {
    background-color: transparent;
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 2.85rem;
    left: 26rem;
    cursor: pointer;

    svg {
      transition: all 0.3s ease-in-out;
      transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
      transform-origin: 50% 50%;
      width: 20px;
      height: 15px;
    }
  }
`;

const LabelView = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 0px;

  label {
    font-size: 1rem;
  }
`;

const ButtonView = styled.div`
  width: 100%;
  margin: 50px 0 0px 0;
`;

const View = styled.div`
  width: 100%;
  position: relative;
  margin-top: 8px;

  border-radius: 4px;
  /* margin-top: 5px; */

  .value {
    width: 100%;
    height: 64px;
    background-color: ${({ theme }) => theme.colors?.inputBackground};
    border-radius: 16px;
    outline: none;
    padding: 16px;
    margin-top: 5px;
    transition: all 0.25s ease-in-out;
    position: relative;
    margin: 0;
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
      background-color: transparent;
      padding: 0;
      margin: 0;
      outline: none;
      border: none;
      cursor: pointer;
      position: absolute;
      top: 25px;
      right: 12px;
      cursor: pointer;

      svg {
        transition: all 0.3s ease-in-out;
        transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
        transform-origin: 50% 50%;
        width: 15px;
        height: 15px;
      }
    }

    p {
      color: ${({ theme }) => theme.colors?.activeTitle};
      text-transform: capitalize;
      font-weight: 400;
    }

    .placeholder {
      color: ${({ theme }) => theme.colors?.placeholder};
    }
  }

  .dropdown {
    width: 100%;
    z-index: 20;
    position: absolute;
    top: 70px;
    background-color: ${({ theme }) => theme.colors?.white};
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    .input {
      outline: none;
      margin-top: 0;
      padding: 10px;
      /* margin-bottom: 10px; */
      font-size: 0.8rem;
      font-weight: 300;
      border: none;
      border-bottom: 1px solid ${({ theme }) => theme.colors?.line};
      width: 100%;
      height: 50px;
      /* position: absolute;
      top: 0px;
      left: 0px; */
    }

    .options {
      width: 100%;
      max-height: 220px;
      padding-top: 10px;
      overflow-y: scroll;

      .option {
        padding: 12px 10px;
        cursor: pointer;
        transition: all 0.3s ease;
        font-size: 0.875rem;
        font-weight: 400;
        display: flex;
        align-items: center;
        width: 100%;
        text-transform: capitalize;
        color: ${({ theme }) => theme.colors?.activeTitle};

        &:hover {
          background-color: ${({ theme }) => theme.colors?.layer};
          border-radius: 0;
        }

        .logo {
          width: 30px;
          height: 30px;
          margin-right: 10px;
          display: inline-block;
          border: 1px solid ${({ theme }) => theme.colors?.line};
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 20px;
            height: 20px;
            object-fit: cover;
          }
        }
      }
    }
  }
`;