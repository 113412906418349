import React, { useState } from 'react';
import styled from 'styled-components';
import UserCreditWalletTransactions from './UserCreditWalletTransactions';
import { UserDebitWalletTransactions } from './UserDebitWalletTransactions';

export const UserWalletTransactions = ({ user }) => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      label: 'Credit Transaction History',
      content: <UserCreditWalletTransactions user={user} />
    },
    {
      label: 'Debit Transaction History',
      content: <UserDebitWalletTransactions user={user} />
    }
  ];

  return (
    <Tab>
      <TabView>
        {tabs.map((item, index) => (
          <button
            key={index}
            className={`tab ${activeTab === index ? 'active' : ''}`}
            onClick={() => setActiveTab(index)}>
            {item.label}
          </button>
        ))}
      </TabView>
      <TabContent>{tabs[activeTab].content}</TabContent>
    </Tab>
  );
};

const Tab = styled.div`
  width: 100%;
  height: 100%;
`;

const TabView = styled.div`
  height: 50px;
  display: flex;
  z-index: 10;
  margin-left: -30px;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors?.white};
  border-bottom: 1px solid ${(props) => props.theme?.colors?.line};

  button {
    padding: 16px 32px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    border-bottom: 2px solid transparent;

    &:hover {
      color: ${(props) => props.theme?.colors?.secondary};
    }
  }

  .active {
    color: ${(props) => props.theme?.colors?.secondary};
    border-bottom: 2px solid ${(props) => props.theme?.colors?.secondary};
  }
`;

const TabContent = styled.div`
  width: 100%;
  height: 100%;
`;