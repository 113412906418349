import PageHeader from '@/components/PageHeader';
import { styled } from 'styled-components';
import UsersTable from './components/UsersTable';
import withAuthentication from '@/hooks/withAuthentication';
import UsersStats from './components/UsersStats';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { usePermissions } from '@/hooks/usePermissions';
import { EmptyState } from '@/components/EmptyState';

function Users() {
  const { hasPermission, superAdmin } = usePermissions();
  const isASuperAdmin = superAdmin(true);
  const canReadUsers = hasPermission('users', 'read');

  return (
    <>
      <PageHeader title="Users" subtitle="View and manage all users" />
      {canReadUsers || isASuperAdmin ? (<UsersView>
        <UsersStats />
        <UsersTable />
      </UsersView>) : (<EmptyState />)}
    </>
  );
}

export default withAuthentication(Users);

const UsersView = styled.div`
  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 100px;
  overflow-x: hidden;
  margin-left: 18%;
`;