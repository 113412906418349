import api from "./api"

export const fetchBillFinancingMetrics = async({ queryKey }) => {
  const [, {startDate, endDate}] = queryKey;
  let url = `/bill-finance/loans/metrics`;
  
  // Create an array to store query parameters
  const params = [];
  
  // Add startDate to params if it exists
  if (startDate) {
    params.push(`startDate=${startDate}`);
  }

  // Add endDate to params if it exists
  if (endDate) {
    params.push(`endDate=${endDate}`);
  }

  // Append query parameters to the URL if any exist
  if (params.length > 0) {
    url += `?${params.join('&')}`;
  }
  
  const response = await api.get(url);
  return response.data;
}

export const fetchStatistics = async() => {
  const response = await api.get(`/bill-finance/loans/stats`);
  return response.data;
}

export const fetchBillFinancingLists = async({ queryKey }) => {
  const [, { page, limit, search, status, orderBy, fetch }] = queryKey;
  let url = `/bill-finance?page=${page}&limit=${limit}`;

  if (search) {
    url += `&search=${search}`;
  }
  if (status) {
    url += `&status=${status}`;
  }
  if (orderBy) {
    url += `&orderBy=${orderBy}`;
  }
  if (fetch) {
    url += `&fetch=${fetch}`;
  }

  const response = await api.get(url);
  return response.data;
}

export const fetchLoanDetail = async({ queryKey }) => {
  const [, id] = queryKey;
  if (id) {
    const response = await api.get(`/bill-finance/${id}`);
    return response.data;
  } else {
    throw new Error('No loan ID provided');
  }
}

export const fetchUserTransactions = async({ queryKey }) => {
  const [, id, {page, limit}] = queryKey;
  if (id) {
    const response = await api.get(`/bill-finance/user-transactions/${id}?page=${page}&limit=${limit}`);
    return response.data;
  } else {
    throw new Error('No loan ID provided');
  } 
}

export const approveBillFinance = async(id) => {
  if (id) {
    const response = await api.patch(`/bill-finance/${id}/APPROVED`);
    return response.data;
  } else {
    throw new Error('No loan ID provided');
  }
}

export const rejectBillFinance = async(id) => {
  if (id) {
    const response = await api.patch(`/bill-finance/${id}/REJECTED`);
    return response.data;
  } else {
    throw new Error('No loan ID provided');
  } 
}

export const markAsCompleted = async(id) => {
  if (id) {
    const response = await api.patch(`/bill-finance/${id}/COMPLETED`);
    return response.data;
  } else {
    throw new Error('No loan ID provided');
  }   
}

export const sendReminder = async ({ queryKey }) => {
  const [, id] = queryKey;
  if (id) {
    const response = await api.get(`/bill-finance/${id}/reminder`);
    return response.data;
  } else {
    throw new Error('No loan ID provided');
  }
}

export const exportPurchaseFinance = async (status, data) => {
  const response = await api.post(`/export/billFinance?status=${status}`, data);
  return response.data;
}

export const recordPayment = async (data) => {
  const response = await api.post(`/bill-finance/make-payment`, data);
  return response.data;
}