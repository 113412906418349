import api from "./api"

export const fetchInvoiceDiscountMetrics = async({ queryKey }) => {
  const [, {startDate, endDate}] = queryKey;
  let url = `/invoice-discount/discounts/metrics`;
  
  // Create an array to store query parameters
  const params = [];
  
  // Add startDate to params if it exists
  if (startDate) {
    params.push(`startDate=${startDate}`);
  }

  // Add endDate to params if it exists
  if (endDate) {
    params.push(`endDate=${endDate}`);
  }

  // Append query parameters to the URL if any exist
  if (params.length > 0) {
    url += `?${params.join('&')}`;
  }
  
  const response = await api.get(url);
  return response.data;
}

export const fetchStatistics = async () => {
  const response = await api.get(`/invoice-discount/discounts/stats`);
  return response.data;
}

export const fetchInvoiceDiscountLists = async ({ queryKey }) => {
  const [, { page, limit, search, status, orderBy, fetch }] = queryKey;
  let url = `/invoice-discount?page=${page}&limit=${limit}`;

  if (search) {
    url += `&search=${search}`;
  }
  if (status) {
    url += `&status=${status}`;
  }
  if (orderBy) {
    url += `&orderBy=${orderBy}`;
  }
  if (fetch) {
    url += `&fetch=${fetch}`;
  }

  const response = await api.get(url);
  return response.data;
}

export const fetchLoanDetail = async ({ queryKey }) => {
  const [, id] = queryKey;
  if (id) {
    const response = await api.get(`/invoice-discount/${id}`);
    return response.data;
  } else {
    throw new Error('No loan ID provided');
  }
}

export const fetchUserTransactions = async ({ queryKey }) =>{
  const [, id, { page, limit }] = queryKey;
  if (id) {
    const response = await api.get(`/invoice-discount/user-transactions/${id}?page=${page}&limit=${limit}`);
    return response.data;
  } else {
    throw new Error('No loan ID provided');
  }
}

export const approveInvoiceDiscount = async (id) => {
  if (id) {
    const response = await api.patch(`/invoice-discount/${id}/APPROVED`);
    return response.data;
  } else {
    throw new Error('No loan ID provided');
  }
}

export const rejectInvoiceDiscount = async (id) => {
  if (id) {
    const response = await api.patch(`/invoice-discount/${id}/REJECTED`);
    return response.data;
  } else {
    throw new Error('No loan ID provided');
  }
}

export const markAsCompleted = async (id) => {
  if (id) {
    const response = await api.patch(`/invoice-discount/${id}/COMPLETED`);
    return response.data;
  } else {
    throw new Error('No loan ID provided');
  }
}

export const sendReminder = async ({ queryKey }) => {
  const [, id] = queryKey;
  if (id) {
    const response = await api.get(`/invoice-discount/${id}/reminder`);
    return response.data;
  } else {
    throw new Error('No loan ID provided');
  }
}

export const exportInvoiceDiscount = async (status, data) => {
  const response = await api.post(`/export/invoiceDiscount?status=${status}`, data);
  return response.data;
}

export const recordPayment = async (data) => {
  const response = await api.post(`/invoice-discount/make-payment`, data);
  return response.data;
}