import { styled } from 'styled-components';
import PropTypes from 'prop-types';
import { numberWithCommas2 } from '@/utils/helpers';
import { Card } from '@/components/MetricsCard';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchComplianceMetrics } from '@/api/complianceApi';
import useOnClickOutside from '@/hooks/useClickOutside';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as FilterIcon } from '@/assets/icons/filter.icon.svg';
import { Oval } from 'react-loader-spinner';

function ComplianceMetrics() {
  const filterOptions = [
    { value: 'allTime', label: 'All Time' },
    { value: 'currentWeek', label: 'Current Week' },
    { value: 'lastWeek', label: 'Last Week' },
    { value: 'currentMonth', label: 'Current Month' },
    { value: 'lastMonth', label: 'Last Month' },
    { value: 'last3Month', label: 'Last Three Months' },
    { value: 'currentYear', label: 'Current Year' },
    { value: 'lastYear', label: 'Last Year' }
  ];

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startDateValue, setStartDateValue] = useState('');
  const [endDateValue, setEndDateValue] = useState('');
  const [fetch, setFetch] = useState('currentMonth');
  const [fetchValue, setFetchValue] = useState(
    filterOptions.find((option) => option.value === 'currentMonth')
  );
  const [showMenu, setShowMenu] = useState(false);
  const [showDateMenu, setShowDateMenu] = useState(false);
  const [showFilterMenu, setShowFilterMenu] = useState(false);

  const menuRef = useRef();
  useOnClickOutside(menuRef, () => setShowMenu(false));

  const dateRef = useRef();
  useOnClickOutside(dateRef, () => setShowDateMenu(false));

  const filterRef = useRef();
  useOnClickOutside(filterRef, () => setShowFilterMenu(false));

  const metrics = useQuery({
    queryKey: ['metrics', { fetch, startDate, endDate }],
    queryFn: fetchComplianceMetrics
  })

  const handleFilterSubmit = () => {
    setStartDate('');
    setEndDate('');
    // Update the `fetch` state with the selected value
    setFetch(fetchValue.value);
    setShowFilterMenu(false);
    // Clear the selected fetch value after submission
    setFetchValue('');
  };

  const handleDateFilter = () => {
    setStartDate(startDateValue);
    setEndDate(endDateValue);
    setEndDateValue('');
    setStartDateValue('');
    setShowDateMenu(false);
  };

  useEffect(() => {
    const currentOption = filterOptions.find((option) => option.value === fetch);
    if (currentOption) {
      setFetchValue(currentOption);
    }
  }, [fetch]);

  const metric = metrics?.data?.data;

  if (metrics.isFetching) {
    return (
      <LoaderView>
        <div className="loading-view">
          <Oval color="#92C22C" width={50} height={50} strokeWidth={5} />
        </div>
      </LoaderView>
    );
  }

  return (
    <div>
      <Filter className="filter">
        <Title>{!startDate && !endDate ? fetchValue.label : `${startDate} to ${endDate}`}</Title>

        <button className="filter-btn" onClick={() => setShowMenu(true)}>
          <FilterIcon /> Filter Metrics
        </button>

        {/* ===== Menu Options ====== */}
        <AnimatePresence>
          {showMenu ? (
            <motion.div
              className="menu-dropdown"
              ref={menuRef}
              initial={{ y: -20 }}
              animate={{ y: 0 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 0.2 }}>
              <button
                className="select"
                onClick={() => {
                  setShowMenu(false);
                  setShowFilterMenu(true);
                }}>
                Filter by Select
              </button>
              <button
                className="select"
                onClick={() => {
                  setShowMenu(false);
                  setShowDateMenu(true);
                }}>
                Filter by Date
              </button>
            </motion.div>
          ) : null}
        </AnimatePresence>

        {/* ===== Date Menu ====== */}
        <AnimatePresence>
          {showDateMenu ? (
            <motion.div
              className="export-menu"
              ref={dateRef}
              initial={{ y: -20 }}
              animate={{ y: 0 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 0.2 }}>
              <div className="heading">
                <span>Filter Metrics</span>
              </div>
              <div className="body">
                <div className="item">
                  <div className="date-input">
                    <label>From:</label>
                    <input
                      type="date"
                      name="startDate"
                      value={startDateValue}
                      onChange={(e) => setStartDateValue(e.target.value)}
                    />
                  </div>
                  <div className="date-input">
                    <label>To:</label>
                    <input
                      type="date"
                      name="endDate"
                      value={endDateValue}
                      onChange={(e) => setEndDateValue(e.target.value)}
                    />
                  </div>
                </div>
                <button className="done" onClick={handleDateFilter}>
                  Filter
                </button>
              </div>
            </motion.div>
          ) : null}
        </AnimatePresence>

        {/* ===== Filter Menu ====== */}
        <AnimatePresence>
          {showFilterMenu ? (
            <motion.div
              className="filter-menu"
              ref={filterRef}
              initial={{ y: -20 }}
              animate={{ y: 0 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 0.2 }}>
              <div className="heading">
                <span>Filters</span>
                <button
                  className="done"
                  disabled={!fetchValue}
                  onClick={() => {
                    handleFilterSubmit();
                  }}>
                  Done
                </button>
              </div>
              <div className="body">
                <div className="item">
                  <h3>Duration</h3>
                  {filterOptions.map((option) => {
                    return (
                      <div className="input" key={option.value}>
                        <input
                          type="radio"
                          name="duration"
                          value={option.value}
                          checked={fetchValue.value === option.value}
                          onChange={(e) => {
                            setFetchValue(option);
                            // setFetchValue(e.target.value);
                          }}
                        />
                        <label>{option.label}</label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </motion.div>
          ) : null}
        </AnimatePresence>
      </Filter>

      <ComplianceMetricsView>
        <div className="card-wrapper">
          <Card>
            <h3>{numberWithCommas2(metric?.periodTotal || '0')}</h3>
            <div>
              <p>{!startDate && !endDate ? fetchValue.label : `${startDate} to ${endDate}`}</p>
              <p className="grey-text">(Compliance per period)</p>
            </div>
          </Card>
          {/* <Card>
            <h3>{numberWithCommas2(metric?.total || '0')}</h3>
            <p>Total submitted</p>
          </Card> */}
          <Card>
            <h3>{numberWithCommas2(metric?.verified || '0')}</h3>
            <p>Approved verification</p>
          </Card>
          <Card>
            <h3>{numberWithCommas2(metric?.declined || '0')}</h3>
            <p>Declined verification</p>
          </Card>
          <Card>
            <h3>{numberWithCommas2(metric?.pending || '0')}</h3>
            <p>Pending verification</p>
          </Card>
        </div>
      </ComplianceMetricsView>
    </div>
  );
}

export default ComplianceMetrics;

const ComplianceMetricsView = styled.div`
  width: 100%;
  height: 130px;
  border-radius: 6px;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;

  .card-wrapper {
    width: 100%;
    display: flex;
    gap: 1rem;

    &:nth-child(even) {
      background-color: ${({ theme }) => theme.colors.alternateBackground2};
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
    }

    .grey-text {
      margin-top: 0.3rem;
      color: ${({ theme }) => theme.colors.grey};
    }
  }
`;

const Title = styled.h3`
  font-size: 1.2rem;
  font-weight: 600;

  color: ${({ theme }) => theme.colors.secondary};
`;

const LoaderView = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .loading-view {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Filter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .menu-dropdown {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 35px;
    right: 0px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow:
      0px 0px 2px rgba(125, 125, 125, 0.1),
      0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 10;
    max-width: 200px;
    border: none;
    padding: 0px 0px;

    .select {
      width: 100%;
      border-radius: 10px;
      padding: 1.2rem 2rem;
      margin-right: 0;

      &:hover {
        background-color: ${({ theme }) => theme.colors.inputBackground};
      }
    }
  }

  .filter-menu {
    position: absolute;
    top: 35px;
    right: 0px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow:
      0px 0px 2px rgba(125, 125, 125, 0.1),
      0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 10;
    width: 300px;
    border: none;
    padding: 20px 12px;

    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      span {
        font-size: 1.1rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.black};
      }

      .done {
        background-color: transparent;
        color: ${({ theme }) => theme.colors?.secondary};
        border: none;
        outline: none;
        font-size: 0.875rem;
        font-weight: 600;
        cursor: pointer;
        margin: 0;
        padding: 0;
        width: auto;
      }
    }

    .body {
      .item {
        width: 100%;
        margin-bottom: 20px;

        h3 {
          font-size: 1rem;
          font-weight: 500;
          color: ${({ theme }) => theme.colors?.black};
          margin-bottom: 10px;
        }

        .input {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          input {
            margin-right: 10px;
            accent-color: ${({ theme }) => theme.colors?.secondary};
            cursor: pointer;
          }
        }

        select {
          width: 100%;
          height: 40px;
          outline: none;
          border: 1px solid ${({ theme }) => theme.colors.line};
          border-radius: 6px;
          padding: 0 10px;
          font-size: 0.875rem;
          font-weight: 400;
          color: ${({ theme }) => theme.colors?.placeholder};
          cursor: pointer;
        }
      }
    }
  }

  .export-menu {
    position: absolute;
    top: 35px;
    right: 0px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow:
      0px 0px 2px rgba(125, 125, 125, 0.1),
      0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 10;
    width: 300px;
    border: none;
    padding: 20px 12px;

    .body {
      margin-top: 20px;

      .item {
        width: 100%;
        margin-bottom: 20px;

        .date-input {
          gap: 0.4rem;
          display: flex;
          align-items: start;
          margin-bottom: 10px;
          flex-direction: column;
          justify-content: start;

          input {
            width: 100%;
            border: none;
            cursor: pointer;
            margin-right: 10px;
            border-radius: 6px;
            padding: 0.5rem 0.65rem;
            color: ${({ theme }) => theme.colors?.black};
            background-color: ${({ theme }) => theme.colors?.inputBackground};
          }
        }
      }

      .done {
        margin: 0;
        width: auto;
        border: none;
        outline: none;
        cursor: pointer;
        font-weight: 600;
        border-radius: 6px;
        padding: 0.6rem 2rem;
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.white};
        background-color: ${({ theme }) => theme.colors?.secondary};
      }
    }
  }

  button {
    width: 120px;
    height: 100%;
    border: none;
    outline: none;
    display: flex;
    margin-right: 1rem;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 10px;
    }
  }

  .sub-update {
    width: 200px;
    background-color: ${({ theme }) => theme.colors?.secondary};
    border-radius: 6px;
    color: ${({ theme }) => theme.colors?.white};
    font-weight: 600;
    transition: all 0.2s ease-in-out;
  }
`;

const MetricView = styled.div`
  width: 32%;
  height: 100%;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.secondary};
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 80%;
  background-color: ${({ theme }) => theme.colors.line};
`;
