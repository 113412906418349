import PropType from 'prop-types';
import { styled } from 'styled-components';

import { ReactComponent as BellIcon } from '@/assets/icons/bell.icon.svg';
import useAuthentication from '@/hooks/useAuthentication';
function PageHeader({ title, subtitle }) {
  const { user } = useAuthentication();

  return (
    <PageHeaderView>
      <TextView>
        <h1>{title}</h1>
        {subtitle && <p>{subtitle}</p>}
      </TextView>
      <ProfileView>
        <div className="notification">
          <button>
            <BellIcon />
          </button>
        </div>
        <div className="profile">
          <div className="profile-image">
            {user && (
              <span>
                {user?.firstName?.charAt(0) || ''}
                {user?.lastName?.charAt(0) || ''}
              </span>
            )}
          </div>
          <div className="name">
            <p>{user?.firstName}</p>
          </div>
        </div>
      </ProfileView>
    </PageHeaderView>
  );
}

export default PageHeader;

PageHeader.propTypes = {
  title: PropType.string,
  subtitle: PropType.string
};

const PageHeaderView = styled.div`
  width: calc(100% - 18%);
  background-color: ${({ theme }) => theme.colors?.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors?.line};
  position: fixed;
  top: 0px;
  right: 0px;
  height: 80px;
  z-index: 1000;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextView = styled.div`
  h1 {
    font-size: 1.5rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors?.secondary};
  }

  p {
    color: ${({ theme }) => theme.colors?.secondary};
    font-size: 0.875rem;
    margin-top: 8px;
  }
`;

const ProfileView = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .notification {
    margin-right: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f3f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .profile {
    width: 200px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 50px;
    border: 1px solid ${({ theme }) => theme.colors?.line};
    padding: 4px 2px;

    .profile-image {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: #f3f2f2;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;

      span {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.secondary};
      }
    }

    .name {
      p {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.secondary};
      }
    }
  }
`;
