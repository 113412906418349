import PageHeader from "@/components/PageHeader";
import withAuthentication from "@/hooks/withAuthentication";
import styled from "styled-components";
import BillFinancingCards from "./components/BillFInancingCards";
import { usePermissions } from "@/hooks/usePermissions";
import { EmptyState } from "@/components/EmptyState";

const BillFinancing = () => {
  const { hasPermission, superAdmin } = usePermissions();
  const isASuperAdmin = superAdmin(true);
  const canReadPurchaseFinancing = hasPermission('purchase-financing', 'read')

  return (
    <>
      <PageHeader title="Purchase Financing" subtitle="View and manage financed purchases" />
      <BillFinancingView>
        {canReadPurchaseFinancing || isASuperAdmin ? (<BillFinancingCards />) : (<EmptyState />)}
      </BillFinancingView>
    </>
  );
}

export default withAuthentication(BillFinancing);

const BillFinancingView = styled.div`
  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 120px;
  overflow-x: hidden;
  margin-left: 18%;
`;