import { fetchLeaderboards } from '@/api/leaderboardApi';
import useOnClickOutside from '@/hooks/useClickOutside';
import useDebounce from '@/hooks/useDebounce';
import { useSortableData } from '@/hooks/useSortableData';
import TableLoader from '@/loaders/TableLoader';
import { useQuery } from '@tanstack/react-query';
import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as SearchIcon } from '@/assets/icons/search.icon.svg';
import { ReactComponent as ArrowIcon } from '@/assets/icons/arrow-tr.icon.svg';
import { ReactComponent as FilterIcon } from '@/assets/icons/filter.icon.svg';
import { ReactComponent as NextIcon } from '@/assets/icons/nav-right.icon.svg';
import { ReactComponent as PrevIcon } from '@/assets/icons/nav-left.icon.svg';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as MoreIcon } from '@/assets/icons/more.icon.svg';
import { numberWithCommas, numberWithCommas2 } from '@/utils/helpers';
import { Oval } from 'react-loader-spinner';

const Monthly = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [showMenu, setShowMenu] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const debouncedSearch = useDebounce(search, 500);

  const ref = useRef();
  useOnClickOutside(ref, () => setShowMenu(false));

  const monthly = useQuery({
    queryKey: ['monthly', { duration: 'monthly' }],
    queryFn: fetchLeaderboards,
  });

  const { items, requestSort, sortConfig } = useSortableData(monthly?.data?.data || []);

  if (monthly.isFetching && !search) {
    return <TableLoader />
  }

  return (
    <MonthlyView>
      <TableHeader>
        {/* <SearchView>
          <SearchIcon />
          <input
            type="search"
            placeholder="Search monthly"
            onChange={(e) => setSearch(e.target.value.trim())}
          />
          {monthly.isFetching && search && <Oval width={20} height={20} strokeWidth={4} />}
        </SearchView>
        <FilterView>
          <button className="export">
            <ArrowIcon /> Export
          </button>
          <button className="filter">
            <FilterIcon /> Filter
          </button>
        </FilterView> */}
      </TableHeader>
      <TableHead>
        <div className='type'>
          <span>User</span>
        </div>
        <div className='type'>
          <span>Email</span>
        </div>
        <div className='type'>
          <span>Transaction count</span>
        </div>
        {/* <div className='type'>
          <span>Transaction Amount</span>
        </div>
        <div className="actions">
        </div> */}
      </TableHead>
      <TableBody>
        {items.map((item, i) => {
          return (
            <div className="item" key={item?.id}>
              <div className="type">
                <span>{item?.companyName}</span>
              </div>
              <div className="type">
                <span className='lowercase'>{item?.email}</span>
              </div>
              <div className="type">
                <span>{numberWithCommas2(item?.total)}</span>
              </div>
              {/* <div className="type">
                <span>₦{numberWithCommas(item?.sum || '00.00')}</span>
              </div> */}

              {/* <div className="actions">
                <button
                  className='menu-icon'
                  onClick={() => {
                    setShowMenu(true);
                    setCurrentIndex(i);
                  }}
                >
                  <MoreIcon />
                </button>

                <AnimatePresence>
                  {showMenu && currentIndex === i ? (
                    <motion.div
                      className="menu-actions"
                        ref={ref}
                        initial={{ y: -20 }}
                        animate={{ y: 0 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}>
                        <button
                          onClick={() => {
                            navigate(`/leaderboard/${item?.userId}`);
                          }}>
                            <span>View details</span>
                        </button>
                    </motion.div>
                  ) : null}
                </AnimatePresence>
              </div> */}
            </div>
          )
        })}
      </TableBody>

      {/* ===== Pagination ====== */}
      {/* <PaginationView>
        <div className="total"><span>{invoices?.data?.data?.meta?.size} results</span></div>
        <div className="paginate">
          <button className="prev" onClick={fetchPrevPage}>
            <PrevIcon />
          </button>
          <span>
            Page {page} of {Math.ceil(wallets?.data?.data?.meta?.totalItems / PAGE_LIMIT)} 
          </span>
          <button className="next" onClick={fetchNextPage}>
            <NextIcon />
          </button>
        </div>
      </PaginationView> */}
    </MonthlyView>
  )
}

export default Monthly

const MonthlyView = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 80px;
`;

const TableHeader = styled.div`
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.line};
  border-left: none;
  border-right: none;
  margin-top: 10px;
`;

const SearchView = styled.div`
  width: 40%;
  height: 40px;
  display: flex;
  align-items: center;

  input {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    margin-left: 10px;
    font-weight: 400;

    &::placeholder {
      font-size: 1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.placeholder};
    }
  }
`;

const FilterView = styled.div`
  width: 30%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    width: 120px;
    height: 100%;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 10px;
    }
  }
`;

const TableHead = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.line};

  div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    height: 100%;

    span {
      font-size: 0.8rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.black};
      /* text-transform: uppercase; */
    }

    .icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 4px;
      width: 10px;
    }
  }

  .ascending {
    color: ${({ theme }) => theme.colors.black};

    .up path {
      stroke: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .descending {
    color: ${({ theme }) => theme.colors.black};

    .down path {
      stroke: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .amount {
    flex: 0.8;
  }

  .year {
    flex: 0.8;
  }

  .reg {
    flex: 0.6;
  }

  .tin {
    flex: 0.6;
  }

  .status {
    flex: 0.5;
  }

  .date {
    flex: 0.5;
  }

  .actions {
    flex: 0.5;
  }
`;

const TableBody = styled.div`
  width: 100%;

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    div {
      flex: 1;
      flex-wrap: wrap;

      span {
        font-size: 0.75rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.secondary};
        text-transform: capitalize;
      }

      .lowercase {
        text-transform: none;
      }
    }

    .amount {
      flex: 0.8;
    }

    .year {
      flex: 0.8;
    }

    .reg {
      flex: 0.6;
    }

    .tin {
      flex: 0.6;
    }

    .status {
      flex: 0.5;

      .verified {
        color: ${({ theme }) => theme.colors?.success};
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 0.75rem;

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.colors?.success};
          margin-right: 4px;
        }
      }

      .pending {
        color: ${({ theme }) => theme.colors?.warning};
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 0.75rem;
        text-transform: capitalize;

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.colors?.warning};
          margin-right: 4px;
        }
      }
    }

    .date {
      flex: 0.5;
    }

    .actions {
      flex: 0.5;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      svg {
        cursor: pointer;

        path {
          stroke: ${({ theme }) => theme.colors?.secondary};
        }
      }

      .menu-actions {
        position: absolute;
        top: 22px;
        right: 0px;
        background-color: ${({ theme }) => theme.colors.white};
        box-shadow:
          0px 0px 2px rgba(125, 125, 125, 0.1),
          0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        z-index: 10;
        width: 180px;
        border: none;

        button {
          width: 100%;
          padding: 16px 12px;
          font-size: 0.875rem;
          background-color: transparent;
          transition: all 0.2s ease-in-out;
          border: none;
          outline: none;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &:hover {
            background-color: ${({ theme }) => theme.colors.layer3};
          }

          span {
            color: ${({ theme }) => theme.colors.activeTitle};
            width: calc(100% - 35px);
            display: flex;
            justify-content: flex-start;
            text-align: left;
            font-weight: 400;
          }
        }
      }
    }
  }
`;

const PaginationView = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .total {
    span {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }

  .paginate {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    button {
      width: 40px;
      height: 40px;
      border: none;
      outline: none;
      background-color: ${({ theme }) => theme.colors?.layer3};
      color: ${({ theme }) => theme.colors?.secondary};
      font-size: 0.875rem;
      font-weight: 500;
      border-radius: 6px;

      &:hover {
        background-color: ${({ theme }) => theme.colors?.layer2};
      }
    }

    span {
      margin: 0 10px;
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }
`;