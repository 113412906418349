import api from './api';

export const fetchInvoices = async ({ queryKey }) => {
  const [, { page, limit, search, orderBy, fetch }] = queryKey;
  let url = `/invoices?page=${page}&limit=${limit}`;
  
  if (search) {
    url += `&search=${search}`;
  }
  if (orderBy) {
    url += `&orderBy=${orderBy}`;
  }
  if (fetch) {
    url += `&fetch=${fetch}`;
  }

  const response = await api.get(url);
  return response.data;
};

export const fetchInvoice = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/invoices/${id}`);
  return response.data;
};

export const exportInvoice = async (data) => {
  const response = await api.post(`/export/invoice`, data);
  return response.data;
}

export const deleteInvoice = async (id) => {
  const response = await api.delete(`/invoices/${id}`);
  return response.data;
};

export const fetchArchivedInvoices = async ({ queryKey }) => {
  const [, { page, limit, search, fetch }] = queryKey;
  let url = `/invoice/archives?page=${page}&limit=${limit}`;

  if (search) {
    url += `&search=${search}`;
  }

  if (fetch) {
    url += `&fetch=${fetch}`;
  }

  const response = await api.get(url);
  return response.data;
};

export const fetchArchivedInvoice = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/archived/invoices/${id}`);
  return response.data;
}