import { Oval } from 'react-loader-spinner'
import styled from 'styled-components'

function PageLoader() {
  return (
    <LoaderView>
      <Oval color="#92C22C" width={50} height={50} strokeWidth={5} />
    </LoaderView>
  )
}

export default PageLoader

const LoaderView = styled.div`
  width: 82%;
  height: calc(100vh - 80px);
  background-color: ${({ theme }) => theme.colors.white};
  height: 100%;
  padding-top: 120px;
  overflow-x: hidden;
  margin-left: 18%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`
